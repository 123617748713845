import React, { useEffect } from 'react'
import BlogAndNewsFrame from './BlogAndNewsFrame'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBlogs } from '../redux/apiCalls';
import MiniLoader from '../component/common/Loader/miniLoader/MiniLoader';
import Loader from '../component/common/Loader/Loader';

const Blog = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    getAllBlogs(dispatch);
  }, [])


  const { isFetching, blogs } = useSelector((state) => state.blog);


  const location = useLocation();
  const blogTitle = decodeURIComponent((location.pathname + location.search).split('/')[2]);

  const currentBlog = useSelector((state) => state.blog.blogs.find((item) => item.title === blogTitle));

  // if (isFetching) {
  //   <MiniLoader />
  // }

  if (isFetching) {
    return <Loader />;
  }

  return (
    // <div>
    // currentBlog ? (
    //   <BlogAndNewsFrame image={currentBlog.blogImage} description = {currentBlog.description} author = {currentBlog.author} itemType = "blog" title={currentBlog.title} itemCategory={currentBlog.category} />
    // ) : (
    //     <h2 className='pt-50 pb-50 text-center'>No content available!</h2>
    // )
    // </div>
    currentBlog && (
      <BlogAndNewsFrame image={currentBlog.blogImage} description={currentBlog.description} author={currentBlog.author} itemType="blog" title={currentBlog.title} itemCategory={currentBlog.category} />
    )
   
  )
}

export default Blog
