import axios from "axios";

// const BASE_URL = "http://localhost:3003/api/";
const BASE_URL = "https://api.meritus.ai/api/";
// const BASE_URL = "https://coral-app-a4xjt.ondigitalocean.app/api/";
// const user = JSON.parse(JSON.parse(localStorage.getItem('persist:root')).loginUser).currentUser;

// if(user){}
// let user = null;
// const persistData = localStorage.getItem('persist:root');

// if (persistData) {
//     const parsedData = JSON.parse(persistData);
//     if (parsedData && parsedData.loginUser) {product
//         user = JSON.parse(parsedData.loginUser).currentUser;
//     }
// }

// let TOKEN;
// if (user) {
//     TOKEN = user.accessToken;
// }
// const TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0OGY1MGJkYzNiODJkZDNhZTdjYWFkYyIsImlzU2Nob29sQWRtaW4iOnRydWUsImlhdCI6MTY4ODczMjMzNCwiZXhwIjoxNjg4OTkxNTM0fQ.m0Eyq-KbbBy5JtDZHb3rZ8PNsZA8A6aSL7Qsyh6ZpxM";

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const fileUploadRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
  withCredentials: true,
});

// export const snapRequest = axios.create({
//     baseURL : "http://139.59.4.119/",
//     withCredentials: true,
// });
// export const userRequest = axios.create({
//     baseURL : BASE_URL,
//     headers : {token : `Bearer ${TOKEN}`}
// });