import 'bootstrap/dist/css/bootstrap.css';
import CCPHeader from '../common/cart-checkout-pay-header/CCPHeader';
import '../checkout-page/Checkout.css'
import '../../styles.css';
import CommonButton from '../common/Button/CommonButton';
import { Col, Container, FormLabel, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FormGroup, FormControl, InputGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { useDispatch, useSelector } from 'react-redux';
import { IMG_URL, MEDIA_URL, changeDateFormat, countriesListWithCode } from '../../config';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { validateRequired } from '../../formValidation';
import { addOrderFailure, addOrderStart, addOrderSuccess } from '../../redux/orderRedux';
import { userRequest } from '../../requestMethod';
import { clearCart, removeCoupon } from '../../redux/cartRedux';
import Loader from '../common/Loader/Loader';
// import { useHistory } from ''


const Checkout = () => {
    const navigate = useNavigate();

    const cartItems = useSelector((state) => state.cart.cartItems);

    const cartValues = useSelector((state) => state.cart);
    const userId = useSelector((state) => state.loginUser.currentUser && state.loginUser.currentUser._id);
    const user = useSelector((state) => state.loginUser.currentUser);
    const [username, setUsername] = useState('');
    const [productsId, setProductsId] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    const cartState = useSelector((state) => state);

    const appliedCoupon = useSelector((state) => state.cart.appliedCoupon)

    const state = useSelector((state) => state);



    useEffect(() => {
        if (user) {
            setUsername(user.firstName + user.lastName);
            // setUserId(user._id)
        }
    }, [user])

    // const redirectPage = () => {
    //     return navigate(`/order-success/status?status=success&orderId=${res.data._id}&orderDate=${changeDateFormat(res.data.createdAt)}`, { replace: true })
    // }

    const handlePayment = async (orderId) => {
        try {
            setActiveLoader(true);
            setLoaderText("Redirecting to payment page");
            // Make a request to your backend to initiate payment
            const response = await userRequest.post('http://localhost:3003/api/payment/initiate', {
                // const response = await axios.post('https://api.meritus.ai/api/payment/initiate', {
                orderId
            });


            if (response && response.status == 200) {
                window.location.replace(response.data?.payment_links.web);
            }
        } catch (error) {
            setActiveLoader(false);
            setLoaderText('');
            console.error(error);
            // Handle error if needed
        }
    };

    // =================== old method when client side handelling used ===============
    // const handlePayment = async (orderId, amount, username, userEmail, orderDateAndTime, currency) => {
    //     try {
    //         setActiveLoader(true);
    //         setLoaderText("Redirecting to payment page")
    //         // Make a request to your backend to initiate payment
    //         const response = await axios.post('http://localhost:3003/api/payment/initiate', {
    //         // const response = await axios.post('https://api.meritus.ai/api/payment/initiate', {
    //             orderId,
    //             amount, username, userEmail, orderDateAndTime, currency
    //         });

    //         if (response && response.status == 200) {
    //             window.location.replace(response.data?.payment_links.web);
    //         }
    //     } catch (error) {
    //         setActiveLoader(false);
    //         setLoaderText('');
    //         console.error(error);
    //         // Handle error if needed
    //     }
    // };


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [countryCode, setCountryCode] = useState('+91');
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [selectedCountry, setSelectedCountry] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    // const handleCountryCodeSelect = (selectedCode) => {
    //     setCountryCode(selectedCode);
    // };

    const handleCountryCodeSelect = (e) => {
        setCountryCode(e);
    };

    const handleCountrySelect = (eventKey) => {
        setSelectedCountry(eventKey);
    };


    // Prepare the products array from cartItems
    const productsArray = cartValues && cartValues.cartItems.map((item) => ({
        productId: item._id, // Adjust the property name based on your cart structure
        quantity: item.quantity,
    }));



    // Validate function for form validation start =======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const firstNameValidErr = validateRequired(firstName);
        const lastNameValidErr = validateRequired(lastName);
        const selectedCountryValidErr = validateRequired(selectedCountry);
        const phoneValidErr = validateRequired(phone);
        const addressValidErr = validateRequired(address);
        const cityValidErr = validateRequired(city);
        const pincodeValidErr = validateRequired(pinCode);


        // let confirmPassword;

        if (firstNameValidErr) {
            formIsValid = false;
            newErrors.firstName = firstNameValidErr;
        }
        if (lastNameValidErr) {
            formIsValid = false;
            newErrors.lastName = lastNameValidErr;
        }
        if (addressValidErr) {
            formIsValid = false;
            newErrors.address = addressValidErr;
        }
        if (phoneValidErr) {
            formIsValid = false;
            newErrors.phone = phoneValidErr;
        }
        if (phoneValidErr == undefined) {
            if (phone.length < 10 || phone.length > 10) {
                formIsValid = false;
                newErrors.phone = "Phone number should have 10 digit only";
            }
        }


        if (cityValidErr) {
            formIsValid = false;
            newErrors.city = cityValidErr;
        }
        if (selectedCountryValidErr) {
            formIsValid = false;
            newErrors.selectedCountry = selectedCountryValidErr;
        }
        if (pincodeValidErr) {
            formIsValid = false;
            newErrors.pinCode = pincodeValidErr;
        }

        setErrors(newErrors);
        return formIsValid;
    };
    // Validate function for form validation end======


    const dispatch = useDispatch();

    const addNewOrder = async (dispatch, order) => {
        setActiveLoader(true);
        setLoaderText("Processing Order")
        dispatch(addOrderStart());
        dispatch(removeCoupon());
        try {
            const res = await userRequest.post("order/create", order);
            // dispatch(addOrderSuccess(res.data));
            if (res && res.status === 200) {
                if (res.data.paymentStatus === "success") {
                    setActiveLoader(false);
                    navigate(`/order-success/status?status=success&orderId=${res.data.orderId}&orderDate=${changeDateFormat(res.data.createdAt)}`, { replace: true });
                    dispatch(clearCart());
                } else {
                    // const orderDateAndTime = res?.data && changeDateFormat(res.data.createdAt);
                    // if (res && res.data && res.data.appliedCoupon) {
                    //     handlePayment(res.data._id, res.data.discountedAmount, username, userId, orderDateAndTime, order.currency);
                    // }
                    // else if (res && res.data) {
                    handlePayment(res.data.orderId);
                    // }
                }

                // ============== Old method handelling everything from client side ===================
                // if (res.data.discountedAmount === 0 || res.data.amount === 0) {
                //     setActiveLoader(false);
                //     navigate(`/order-success/status?status=success&orderId=${res.data._id}&orderDate=${changeDateFormat(res.data.createdAt)}`, { replace: true })
                //     dispatch(clearCart());
                // } else {
                //     const orderDateAndTime = res?.data && changeDateFormat(res.data.createdAt);
                //     if (res && res.data && res.data.appliedCoupon) {
                //         handlePayment(res.data._id, res.data.discountedAmount, username, userId, orderDateAndTime, order.currency);
                //     }
                //     else if (res && res.data) {
                //         handlePayment(res.data._id, res.data.amount, username, userId, orderDateAndTime, order.currency);
                //     }
                // }
            }
        } catch (error) {
            setActiveLoader(false);
            dispatch(addOrderFailure());
            setError(true);
            setErrorMessage("Something went wrong!");
        }
    };



    const handleSubmit = () => {
        if (validateForm()) {
            const fullPhoneNumber = countryCode + phone;
            // Create the address object
            const addressObject = {
                firstName,
                lastName,
                phoneNumber: fullPhoneNumber,
                address,
                city,
                pinCode,
                country: selectedCountry,
            };

            // Prepare the order data
            let orderData
            if (appliedCoupon) {
                orderData = {
                    userId: userId,
                    products: productsArray,
                    // amount: cartItems[0].showPriceInRupees ? cartValues.total : cartValues.total,
                    address: addressObject,
                    appliedCoupon,
                    // appliedCoupon: cartValues?.appliedCoupon,
                    // discount: cartValues?.discount,
                    // discountType: cartValues?.discountType,
                    // discountedAmount: cartValues?.discountedAmount,
                    // isCouponApplied: true
                    currency: cartItems[0].showPriceInRupees ? "INR" : "USD"
                };
            }
            else {
                orderData = {
                    userId: userId,
                    products: productsArray,
                    // amount: cartItems[0].showPriceInRupees ? cartValues.total : cartValues.total,
                    address: addressObject,
                    currency: cartItems[0].showPriceInRupees ? "INR" : "USD"
                };
            }


            addNewOrder(dispatch, orderData);
        }
    };


    const [searchTermForCountryName, setSearchTermForCountryName] = useState('');
    const [searchTermForCountryCode, setSearchTermForCountryCode] = useState('');

    const handleSearchChangeForCountryName = (e) => {
        // Handle the change of the search input
        setSearchTermForCountryName(e.target.value);
    };
    const handleSearchChangeForCountryCode = (e) => {
        // Handle the change of the search input
        setSearchTermForCountryCode(e.target.value);
    };

    // Filter products based on the search term
    const filteredCountriesName = countriesListWithCode?.filter(country =>
        country.name.toLowerCase().includes(searchTermForCountryName?.toLowerCase())
    );

    // Filter products based on the search term
    const filteredCountriesCode = countriesListWithCode?.filter(country =>
        country.code.toLowerCase().includes(searchTermForCountryCode?.toLowerCase())
    );




    if (activeLoader) {
        return (
            <Loader title={loaderText} />
        )
    }

    // if (cartItems.length < 1) {
    //     return (
    //         <div className='checkout-page pb-100 pt-100'>

    //         </div>
    //     )
    // }

    return (
        <div className='checkout-page pb-100'>
            <div className='checkout-header'>
                <CCPHeader
                    HeaderTitle="Checkout"
                />
            </div>

            {
                cartItems.length < 1 ? (
                    <p className='text-center pt-100'>You don't have any product in cart to checkout.</p>
                ) : (
                    <Container className='mt-50'>
                        <Row>
                            <Col lg={6} className='gx-5'>
                                <div className='contact-billing-form'>
                                    <Form>
                                        <div className='billing-detail-form pt-40'>
                                            <h4 className='checkout-billing-info pb-40'>Shipping Details</h4>

                                            <div className='name-ip-field'>
                                                <Row>
                                                    {/* First name Field */}
                                                    <Col lg={6} md={12}>
                                                        <FormGroup row>
                                                            <Form.Label className='cb-form-label'>
                                                                First Name:
                                                            </Form.Label>
                                                            <Form.Control className='cb-input-fields' type='text' onChange={(e) => setFirstName(e.target.value)}></Form.Control>
                                                            <span className="error-text">{errors.firstName}</span>
                                                        </FormGroup>
                                                    </Col>

                                                    {/* Last name field */}
                                                    <Col lg={6} md={12}>
                                                        <FormGroup row>
                                                            <Form.Label className='cb-form-label'>
                                                                Last Name:
                                                            </Form.Label>
                                                            <Form.Control className='cb-input-fields' type='text' onChange={(e) => setLastName(e.target.value)}></Form.Control>
                                                            <span className="error-text">{errors.lastName}</span>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>

                                            {/* Start Phone Number Input field */}
                                            <Form.Group controlId="phoneNumber">
                                                <Form.Label className='cb-form-label mt-15'>
                                                    Phone Number
                                                </Form.Label>
                                                <InputGroup row className="mb-3 ph-country-code">
                                                    {/* <InputGroup.Prepend> */}
                                                    <DropdownButton
                                                        as={InputGroup.Prepend}
                                                        className='ph-country-code'
                                                        variant="outline-secondary"
                                                        title={countryCode}
                                                        id="input-group-dropdown-1"
                                                        onSelect={handleCountryCodeSelect}
                                                    >
                                                        <input type="text" className='checkout-search-bar' placeholder='Search here...' value={searchTermForCountryCode}
                                                            onChange={handleSearchChangeForCountryCode} />
                                                        {
                                                            filteredCountriesCode?.map((country) => {
                                                                return (
                                                                    <Dropdown.Item eventKey={country.code}>{country.code}</Dropdown.Item>
                                                                )
                                                            })
                                                        }
                                                        {/* <Dropdown.Item eventKey="+91">+91</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+92">+92</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+93">+93</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+94">+94</Dropdown.Item> */}
                                                    </DropdownButton>
                                                    {/* </InputGroup.Prepend> */}
                                                    <Form.Control className='cb-input-fields ph-no-input-fields' aria-label="Phone Number" onChange={(e) => setPhone(e.target.value)} />
                                                </InputGroup>
                                                <span className="error-text">{errors.phone}</span>
                                            </Form.Group>
                                            {/* End Phone Number Input field */}

                                            {/* User Address Field */}
                                            <Form.Group className="mb-3 text-area-wrapper" controlId="ControlTextarea1">
                                                <Form.Label className='cb-form-label'>
                                                    Address
                                                </Form.Label>
                                                <Form.Control className='p-20' as="textarea" rows={3} onChange={(e) => setAddress(e.target.value)} />
                                                <span className="error-text">{errors.address}</span>
                                            </Form.Group>

                                            <div className='city-pincode'>
                                                <Row>
                                                    {/* User City Field */}
                                                    <Col lg={6} md={12}>
                                                        <Form.Group className='mb-3' controlId="userCity">
                                                            <FormLabel className='cb-form-label'>
                                                                City Name
                                                            </FormLabel>
                                                            <Form.Control className='cb-input-fields' type='text' onChange={(e) => setCity(e.target.value)} />
                                                            <span className="error-text">{errors.city}</span>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} md={12}>
                                                        <Form.Group className='mb-3' controlId="userPincode">
                                                            <FormLabel className='cb-form-label'>
                                                                Pin Code
                                                            </FormLabel>
                                                            <Form.Control className='cb-input-fields' onChange={(e) => setPinCode(e.target.value)} />
                                                            <span className="error-text">{errors.pincode}</span>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>

                                            {/* User country */}

                                            <FormLabel className='cb-form-label'>
                                                Country
                                            </FormLabel>
                                            <InputGroup className='cb-dropdown-field'>
                                                <DropdownButton className='country-list-dropdown' title={selectedCountry || 'Select Country'} onSelect={handleCountrySelect}>
                                                    {/* <Dropdown.Item> */}
                                                    <input type="text" className='checkout-search-bar' placeholder='Search here...' value={searchTermForCountryName}
                                                        onChange={handleSearchChangeForCountryName} />
                                                    {/* </Dropdown.Item> */}
                                                    {
                                                        filteredCountriesName?.map((country) => {
                                                            return (
                                                                <Dropdown.Item eventKey={country.name}>{country.name}</Dropdown.Item>
                                                            )
                                                        })
                                                    }
                                                    {/* <Dropdown.Item eventKey="India">India</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Canada">Canada (CA)</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Australia">Australia (AU)</Dropdown.Item>
                                                    <Dropdown.Item eventKey="New Zealand">New Zealand (NZ)</Dropdown.Item>
                                                    <Dropdown.Item eventKey="United Kingdom">United Kingdom (UK)</Dropdown.Item>
                                                    <Dropdown.Item eventKey="United States">United States of America (USA)</Dropdown.Item> */}
                                                </DropdownButton>
                                                <span className="error-text">{errors.selectedCountry}</span>
                                            </InputGroup>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                            <Col lg={6} className='gx-5'>
                                <div className='all-products-amt mt-50'>
                                    <div className='checkout-products'>
                                        {
                                            cartItems.map((item) => {
                                                return (
                                                    <div className='checkout-pg-product ptb-10 mb-3'>
                                                        <div className='product-img-c'>
                                                            <img src={MEDIA_URL + item.productImg} alt='product-img' />
                                                        </div>
                                                        <div className='product-name-c'>
                                                            <p className='text-capitalize'>{item.title}</p>
                                                        </div>
                                                        <div className='product-price-c'>
                                                            {
                                                                item.showPriceInRupees ? (
                                                                    <p>&#8377;{item.price} * {item.quantity}</p>
                                                                ) : (
                                                                    <p>&#x24;{item.price} * {item.quantity}</p>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    {/* <div className="promocode-wrapper">
                                <p className='text-center pt-2 mb-0'>Have a promocode?</p>
                            </div> */}


                                    <div className='grand-total'>
                                        <div className='total-txt'>
                                            <p>Total</p>
                                        </div>
                                        <div className='gross-amount'>
                                            {
                                                appliedCoupon ? (
                                                    cartItems[0].showPriceInRupees ? (
                                                        <p><s>&#8377;{cartValues.total}</s> &#8377;{cartValues.discountedAmount}</p>
                                                    ) : (
                                                        <p><s>&#x24;{cartValues.total}</s> &#x24;{cartValues.discountedAmount}</p>
                                                    )

                                                ) : (
                                                    cartItems[0].showPriceInRupees ? (
                                                        <p>&#8377;{cartValues.total}</p>
                                                    ) : (
                                                        <p>&#x24;{cartValues.total}</p>
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={12}>
                                {/* BAck to Cart And Payement page Button  */}
                                <div className='cart-payement-pg-btns mt-50'>
                                    <Link to={'/cart'}>
                                        <CommonButton
                                            btnClass='back-to-cart'
                                            btnName='Back to cart'
                                        />
                                    </Link>

                                    <CommonButton
                                        btnClass='go-to-payement'
                                        btnName='Place Order'
                                        onClickAction={handleSubmit}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                )
            }
        </div>
    )
}

export default Checkout;