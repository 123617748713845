import "bootstrap/dist/css/bootstrap.css";
import CCPHeader from "../common/cart-checkout-pay-header/CCPHeader";
import "../cart-pages/Cart.css";
import { Container, Row, Col } from "react-bootstrap";
import { MdDeleteOutline } from "react-icons/md";
import Form from "react-bootstrap/Form";
import CommonButton from "../common/Button/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { MEDIA_URL } from "../../config";
import {
  addCoupon,
  decreaseQuantity,
  increaseQuantity,
  removeCoupon,
  removeFromCartFailure,
  removeFromCartStart,
  removeFromCartSuccess,
  setDiscountedAmount,
} from "../../redux/cartRedux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethod";
import { validateRequired } from "../../formValidation";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { MdDone } from "react-icons/md";

// import { removeFromCart } from '../../redux/cartRedux';

const Cart = () => {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const cartValues = useSelector((state) => state.cart);
  const state = useSelector((state) => state);

  const dispatch = useDispatch();

  const removeFromCart = async (dispatch, productId) => {
    dispatch(removeFromCartStart());
    try {
      // const res = await userRequest.post('user/create', product);
      dispatch(removeFromCartSuccess(productId));
      // if (res.status === 200) {
      //     setSuccess(true);
      //     setSuccessMessage("product has been added!");
      //     setFirstName('');
      //     setLastName('');
      //     setEmail('');
      //     setPhone('');
      //     setDateOfBirth('');
      // }
    } catch (error) {
      dispatch(removeFromCartFailure());
      // if (error.response.status === 409) {
      //     setEmailError(true);
      //     setEmailErrorMessage(error.response.data);
      // }

      // else {
      //     setError(true);
      //     setErrorMessage("Something went wrong!");
      // }
    }
  };

  const removeCart = (productId) => {
    removeFromCart(dispatch, productId);
  };

  const increaseItem = (itemId) => {
    dispatch(increaseQuantity(itemId));
    // if(isCouponApplied){
    //     const discountedTotal = calculateDiscountedTotal(
    //         // Recalculate the total based on the updated cart state
    //         calculateUpdatedCartTotal(cartValues, productId),
    //         discountType,
    //         amount
    //     );
    //     dispatch(setDiscountedAmount(discountedTotal));
    // }
  };

  const decreaseItem = (itemId) => {
    dispatch(decreaseQuantity(itemId));
  };

  const handleRemoveCoupon = () => {
    setIsCouponApplied(false);
    setCouponCode("");
    dispatch(removeCoupon());
  };

  useEffect(() => {
    cartItems.length < 1 && handleRemoveCoupon();
  }, [cartItems]);

  const [couponCode, setCouponCode] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponError, setCouponError] = useState(false);
  const [couponErrorMessage, setCouponErrorMessage] = useState("");
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  // const [showDelete, setShowDelete] = useState(true);
  // const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const appliedCoupon = useSelector((state) => state.cart.appliedCoupon);

  useEffect(() => {
    appliedCoupon ? setIsCouponApplied(true) : setIsCouponApplied(false);
  }, [appliedCoupon]);


  // Validate function for form validation start =======
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    const couponCodeValidErr = validateRequired(couponCode);

    if (couponCodeValidErr) {
      formIsValid = false;
      newErrors.couponCode = couponCodeValidErr;
    }

    setErrors(newErrors);
    return formIsValid;
  };
  // Validate function for form validation end======


  const calculateDiscountedTotal = (
    originalAmount,
    discountType,
    discountAmount
  ) => {
    // Assuming "originalAmount" is the total amount in the cart
    let discountedTotal = originalAmount;

    // Check the discount type (percentage or fixed) and apply the discount
    if (
      discountType === "percentage" &&
      discountAmount >= 0 &&
      discountAmount <= 100
    ) {
      // Percentage discount
      discountedTotal -= (originalAmount * discountAmount) / 100;
    } else if (discountType === "fixed" && discountAmount >= 0) {
      // Fixed discount
      discountedTotal -= discountAmount;
    }

    // Make sure the discounted total is not negative
    if (discountedTotal < 0) {
      discountedTotal = 0;
    }

    return discountedTotal;
  };

  useEffect(() => {
    if (isCouponApplied) {
      const discountedTotal = calculateDiscountedTotal(
        cartValues.total,
        cartValues.discountType,
        cartValues.discount
      );
      dispatch(setDiscountedAmount(discountedTotal));
    }
  }, [cartValues.total, cartValues.quantity, cartItems]);

  const handleCoupon = async () => {
    setCouponError(false);
    setCouponErrorMessage("");
    if (validateForm()) {
      setIsLoaderActive(true);
      try {
        const res = await userRequest.post("/discountCoupon/validate", {
          couponCode,
        });
        if (res && res?.status === 200) {
          setIsLoaderActive(false);
          setCouponError(false);
          setCouponErrorMessage("");
          const { isValid, discountType, amount, minOrder } = res.data;
          if (isValid) {
            // Check if the cart value is greater than or equal to the minOrder value
            if (cartValues.total >= minOrder) {
              // Update your Redux store with the coupon details
              dispatch(
                addCoupon({
                  couponCode,
                  discountType, // "percentage" or "fixed"
                  amount,
                })
              );
              setIsCouponApplied(true);

              // Calculate and set the discounted total in the store
              const discountedTotal = calculateDiscountedTotal(
                cartValues.total,
                discountType,
                amount
              );
              dispatch(setDiscountedAmount(discountedTotal));
            } else {
              setCouponError(true);
              setCouponErrorMessage(
                `Cart value should be greater than ${minOrder}`
              );
              // Handle the case where the cart value is below the minOrder value
              // Display an error message, prevent applying the coupon, etc.
            }
          } else {
            // Handle invalid coupon, show an error message, etc.
          }
        }
      } catch (error) {
        if (error && error.response?.status == 400) {
          setIsLoaderActive(false);
          setCouponError(true);
          setCouponErrorMessage(error.response.data);
        } else {
          setIsLoaderActive(false);
        }
      }
    }
  };

  return (
    <div className="cart-page">
      {/* Cart Page Header  */}
      <div className="cart-header">
        <CCPHeader HeaderTitle="my cart" />
      </div>
      <Container>
        {/* Cart Page Table */}
        {cartItems.length > 0 ? (
          <>
            <Row>
              <Col lg={12} className="cart-tab-heads mt-20">
                <div className="d-flex card-heads align-items-center">
                  <p className="w-60 text-start">Product</p>
                  <p className="w-10">Price</p>
                  <p className="w-20">Quantity</p>
                  <p className="w-10">Sub Total</p>
                </div>
              </Col>
              {cartItems.map((item, index) => {
                return (
                  <>
                    <Col lg={7} md={7}>
                      {/* Cart Product */}
                      <div className="product-width">
                        <div className="cart-product mt-50">
                          <div className="serial-no">
                            <h2>{index + 1}</h2>
                          </div>
                          <div className="product-thumbnail">
                            <img src={MEDIA_URL + item.productImg} alt="" />
                          </div>
                          <div className="cart-product-name">
                            <p className="cart-product-title">{item.title}</p>
                            <p className="shortDesc">{item.shortDescription}</p>
                          </div>
                          <div className="delete-icon">
                            <MdDeleteOutline
                              onClick={() => removeCart(item._id)}
                            />
                            {/* {
                                                                    !showDelete && (
                                                                        <div className="deleteWrapper">
                                                                            <button onClick={() => setShowDelete(true)}>
                                                                                <RxCross2 /> 
                                                                            </button>

                                                                            <button onClick={() => removeCart(item._id)}>
                                                                                <MdDone />
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                } */}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={5} md={5}>
                      <div className="product-price-quantity border-bottom">
                        {/* Cart Product Price  */}
                        <div className="cp-product-price text-center">
                          {
                            item.showPriceInRupees ? (
                              <p>
                                <span>Price:</span> &#8377; {item.price}
                              </p>
                            ) : (
                              <p>
                                <span>Price:</span> &#x24; {item.price}
                              </p>
                            )
                          }

                        </div>

                        {/* Product Quantity */}
                        <div className="product-quantity text-center">
                          {
                            item.isRobotixProduct && (
                              <span
                                className="btn"
                                onClick={() => decreaseItem(item._id)}
                              >
                                -
                              </span>
                            )
                          }

                          <p>
                            {/* <span>Qty:</span> */}
                            {item.quantity}
                          </p>
                          {
                            item.isRobotixProduct && (
                              <span
                                className="btn"
                                onClick={() => increaseItem(item._id)}
                              >
                                +
                              </span>
                            )
                          }

                        </div>

                        {/* Sub Total Amount */}
                        <div className="sub-total-amt text-center">
                          {
                            item.showPriceInRupees ? (
                              <p>
                                <span>Sub-total:</span> &#8377;{" "}
                                {item.price * item.quantity}
                              </p>
                            ) : (
                              <p>
                                <span>Sub-total:</span>  &#x24;{" "}
                                {item.price * item.quantity}
                              </p>
                            )
                          }
                        </div>
                      </div>
                    </Col>
                  </>
                );
              })}

              <Col lg={7} md={7}></Col>

              <Col lg={5} md={5} className="cart-value-container">
                {/* Sub Total Amount */}
                <div className="sub-total d-flex">
                  <Col lg={6}>
                    <p className="calc-txt">Sub Total</p>
                  </Col>
                  <Col lg={6} className="amount-txt">

                    {
                      cartItems[0].showPriceInRupees ? (
                        //   <p>
                        //   <span>Sub-total:</span> &#8377;{" "}
                        //   {item.price * item.quantity}
                        // </p>
                        <p>&#8377; {cartValues.total}</p>
                      ) : (
                        <p>&#x24; {cartValues.total}</p>
                      )
                    }
                  </Col>
                </div>

                {/* Coupon Code field */}
                <div className="coupon-field-container d-flex">
                  {isCouponApplied ? (
                    <Col lg={12}>
                      <div className="coupon-success-wrapper">
                        <div className="coupon-content-wrapper">
                          <IoCheckmarkDoneCircleOutline className="coupon-success-icon" />
                          <div>
                            <p className="text-uppercase">
                              <strong>{appliedCoupon}</strong> applied
                            </p>
                            {cartValues.discountType &&
                              cartValues.discountType === "percentage" && (
                                <p className="coupon-detail">
                                  - &#8377;
                                  {` ${cartValues.total -
                                    cartValues.discountedAmount
                                    } (${cartValues.discount}% OFF)`}
                                </p>
                              )}
                            {cartValues.discountType &&
                              cartValues.discountType === "fixed" && (
                                <p className="coupon-detail">
                                  - &#8377;
                                  {` ${cartValues.discount} (FLAT ${cartValues.discount} OFF)`}
                                </p>
                              )}
                          </div>
                        </div>
                        <button
                          className="coupon-remove-btn"
                          onClick={handleRemoveCoupon}
                        >
                          Remove
                        </button>
                      </div>
                    </Col>
                  ) : (
                    <>
                      <Col lg={6}>
                        <p className="calc-txt">Coupon code</p>
                      </Col>
                      <Col lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="couponForm.ControlTextarea1"
                        >
                          <Form.Control
                            className="cb-input-fields"
                            type="text"
                            onChange={(e) => setCouponCode(e.target.value)}
                          />
                          <span className="error-text">
                            {errors.couponCode}
                          </span>
                          {couponError && (
                            <span className="error-text">
                              {couponErrorMessage}
                            </span>
                          )}
                        </Form.Group>
                        <div className="coupon-btn-wrapper position-relative d-flex justify-content-end">
                          <button
                            className={`${isLoaderActive
                              ? "button-loader load"
                              : "submit-coupon"
                              }`}
                            onClick={handleCoupon}
                          >
                            <span>apply</span>
                          </button>
                        </div>
                      </Col>
                    </>
                  )}
                </div>

                {/* Total Cart Amount */}
                <div className="total-amt d-flex">
                  <Col lg={6}>
                    <p className="calc-txt">Total</p>
                  </Col>
                  <Col lg={6} className="amount-txt amt-txt-color">
                    {isCouponApplied ? (
                      <>
                        {
                          cartItems[0].showPriceInRupees ? (
                            <>
                              <p className="discounted-text">
                                <s>&#8377; {cartValues.total}</s>
                              </p>
                              <p>&#8377; {cartValues.discountedAmount}</p>
                            </>
                          ) : (
                            <>
                              <p className="discounted-text">
                                <s>&#x24; {cartValues.total}</s>
                              </p>
                              <p>&#x24; {cartValues.discountedAmount}</p>
                            </>
                          )
                        }

                      </>
                    ) : (
                      // {
                      cartItems[0].showPriceInRupees ? (
                        <p>&#8377; {cartValues.total}</p>
                      ) : (
                        <p>&#x24; {cartValues.total}</p>
                      )
                    )}
                  </Col>
                </div>
              </Col>
            </Row>
            <div className="checkout-btn">
              <Link to={"/checkout"}>
                <CommonButton btnClass="checkout" btnName="checkout" />
              </Link>
            </div>
          </>
        ) : (
          <div className="empty-cart-wrapper text-center pt-50  pb-50">
            <p className="text-center">No products added yet!</p>
            <Link to="/all-courses">
              <CommonButton
                btnClass="continue-shopping"
                btnName="Continue Shopping"
              />
            </Link>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Cart;