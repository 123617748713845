import { Helmet } from "react-helmet";
import Banner from "../component/TeacherPdp/Banner.jsx";
import SchoolForm from "../component/TeacherPdp/SchoolForm.jsx";
import "./TeacherPdp.css";
function TeacherPdp() {
  return (
    <div>
      <Helmet>
        <title>Chatgpt Workshop For Teachers | Meritus AI
        </title>
        <meta name="description" content="Dear educators, seize the chance to join our AI workshops for teachers and maximize this incredible opportunity." />
        <link rel="canonical" href="https://meritus.ai/TeacherPdp" />
      </Helmet>
      <Banner />

      <section className="waht_teacher_pdp container pb-100 pt-100">
        <div className="row">
          <div className="col-md-5">
            <img src={`${process.env.PUBLIC_URL}/images/TeacherPdp/pdp.png`} />
          </div>
          <div className="col-md-7 tpdp-content">
            <h2 className="inb dPink tpdp-heading pb-20">
              What is Teacher PDP ?
            </h2>
            <p>
              Discover the revolutionary world of AI , GPT and Data Science and
              its transformative power in school education with our foundational
              Professional Development Program.
            </p>
            <p>
              We recognize that schools like yours are consistently seeking new
              frontiers and the latest technological advancements to remain at
              the cutting edge of innovation. Meritus is excited to extend an
              exclusive offer of
              <b>&nbsp;2 Complimentary Workshops for your Teachers.</b>
            </p>
          </div>
        </div>
        <p className="pt-20 m-0 fw-500">
          We believe this initiative will greatly benefit your school community
          by enhancing Teachers skills, fostering creativity, igniting
          innovation aligned with NEP 2020 educational goals.
        </p>
        <p className="pt-20 m-0 fw-500">
          We trust your teaching team and school leaders will be able to
          incorporate AI in your education system. Schools that express interest
          can apply for the <b className="dPink">Educator Impact grant, </b>
          positioning themselves to gain a significant edge in preparing their
          teachers to become "Future Ready."
        </p>
      </section>

      <section className=" gpt_workshop ">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-4">
              <img
                src={`${process.env.PUBLIC_URL}/images/TeacherPdp/gpt.png`}
              />
            </div>

            <div className="col-md-8">
              <div className="">
                <h2>Chat GPT Workshop</h2>
                <p className="fw-500">
                  Join us on this journey of exploration and unlock the future
                  of teaching with concrete examples of how to co-pilot with
                  ChatGPT.
                </p>
                <span>Course details : </span>
                <ul>
                  <li>
                    5 hour complimentary virtual on-line ChatGPT Master Class
                    for Teachers and limited to 20 seats.
                  </li>
                  <li>
                    Classes will be both tutor led and Self learning mode via
                    LMS.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ai_workshop pt-100">
        <div className="container">
          <img src={`${process.env.PUBLIC_URL}/images/TeacherPdp/robo.png`} />
          <div className="ai-workshop-wrapper">
            <h2>AІ Workshop</h2>
            <p className="fw-500">
              Empower your teachers to deep dive into the introductory course on
              the fundamentals of Artificial Intelligence which will equip them
              with the foundational knowledge to understand the profound impact
              of AI in our society and its increasing relevance to the field of
              education.
            </p>
            <span>Course details:</span>
            <ul>
              <li>
                Two 5 hour complimentary Master Class in AI is mapped to CBSE
                curriculum and limited to 5 teachers teaching students of grades
                4 to 5 and 5 teachers teaching students of grades 6 to 8.
              </li>
              <li>
                The virtual on-line Classes will be both tutor led and Self
                learning mode via LMS.
              </li>
              <li>
                Our hands-on AI classes will utilize the Scratch AI extensions
                platform and will take place in the CS labs.
              </li>
              <li>
                This state-of-art program aims to empower teachers in
                establishing a cutting-edge AI lab within your school and equip
                them with practical skills to teach real-world AI concepts to
                students in grades 4 to 5 and 6 to 8.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section>
        <SchoolForm />
      </section>
    </div>
  );
}
export default TeacherPdp;
