import React, { useEffect, useState } from "react";
import EventBanner from "../component/events/EventBanner";
import "./NewsAndEvents.css";
import BlogCard from "../component/BlogCard.jsx";
import TabWrapper from "../component/common/tab/TabWrapper";
import "../component/common/tab/TabWrapperone.css";
import { async } from "@vimeo/player";
import { publicRequest, userRequest } from "../requestMethod";
import EventCard from "./EventCard";
import NewsCard from "./NewsCard";
import Loader from "../component/common/Loader/Loader.jsx";
const NewsAndEvents = () => {
  const blogcardData = [
    {
      id: 1,
      imageUrl: "images/latestNews/img-1.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and tyspesetting industry.",
      read: "/",
    },
    {
      id: 2,
      imageUrl: "images/latestNews/img-2.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      read: "/",
    },
    {
      id: 3,
      imageUrl: "images/latestNews/img-3.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      read: "/",
    },
    {
      id: 1,
      imageUrl: "images/latestNews/img-1.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and tyspesetting industry.",
      read: "/",
    },
    {
      id: 2,
      imageUrl: "images/latestNews/img-2.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      read: "/",
    },
    {
      id: 3,
      imageUrl: "images/latestNews/img-3.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      read: "/",
    },
  ];

  const getNewsData = async () => {
    try {
      const res = await userRequest.get(`/news/all`);
    } catch (error) { }
  };

  const [newsData, setNewsData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [newsAndEvents, setNewsAndEvents] = useState([]);
  const [isDataFetching, setisDataFetching] = useState(false);


  const fetchNews = async () => {
    try {
      setisDataFetching(true)
      const newsResponse = await publicRequest.get("/news/all");
      if (newsResponse?.status === 200) {
        const activeNewsOnly = newsResponse?.data?.filter(
          (item) => item.status
        );
        setNewsData(activeNewsOnly);
        setisDataFetching(false);
      }
    } catch (error) {
      setisDataFetching(false);
    }
  };

  const fetchEvents = async () => {
    try {
      setisDataFetching(true)
      const eventsResponse = await publicRequest.get("/event/all");
      if (eventsResponse?.status === 200) {
        const activeNewsOnly = eventsResponse?.data?.filter(
          (item) => item.status
        );
        setEventsData(activeNewsOnly);
        setisDataFetching(false);
      }
    } catch (error) {
      setisDataFetching(false);
    }
  };

  useEffect(() => {
    fetchNews()
  }, []);

  useEffect(() => {
    fetchEvents()
  }, [])

  // // Fetch news and events data
  // useEffect(() => {
  //   const fetchNewsAndEvents = async () => {
  //     try {
  //       const newsResponse = await publicRequest.get("/news/all");
  //       const eventsResponse = await publicRequest.get("/event/all");

  //       const activeNewsOnly = newsResponse?.data?.filter(
  //         (item) => item.status
  //       );
  //       const activeEventOnly = eventsResponse?.data?.filter(
  //         (item) => item.status
  //       );

  //       setNewsData(activeNewsOnly);
  //       setEventsData(activeEventOnly);
  //     } catch (error) {}
  //   };

  //   fetchNewsAndEvents();
  // }, []);

  // useEffect(() => {
  //   setNewsAndEvents([...newsData, ...eventsData]);
  //   console.log("updated news and events ==>", newsAndEvents)
  // }, [newsData, eventsData]);

  useEffect(() => {
    const combinedData = [...newsData, ...eventsData];
    const sortedData = combinedData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    setNewsAndEvents(sortedData);
  }, [newsData, eventsData]);


  if (newsAndEvents.length > 0) {
  }

  const tabData = {
    titles: ["News & Events", "Just News", "Just Events"],
    contents: [
      <section className="pt-100 pb-100">
        <div className="container">
          <div className="row">
            {newsAndEvents.map((data, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                {data?.type === "event" && (
                  <EventCard
                    image={data?.eventImage}
                    title={data?.title}
                    eventId={data?._id}
                    description={data?.description}
                  />
                )}

                {data?.type === "news" && (
                  <NewsCard
                    image={data?.newsImage}
                    title={data?.title}
                    eventId={data?._id}
                    description={data?.description}

                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </section>,
      <section className="pt-100 pb-100">
        <div className="container">
          <div className="row">
            {newsData.map((data, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <NewsCard
                  image={data?.newsImage}
                  title={data?.title}
                  eventId={data?._id}
                  description={data?.description}
                />
              </div>
            ))}
          </div>
        </div>
      </section>,
      <section className="pt-100 pb-100">
        <div className="container">
          <div className="row">
            {eventsData.map((data, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <EventCard
                  image={data?.eventImage}
                  title={data?.title}
                  eventId={data?._id}
                  description={data?.description}
                />
              </div>
            ))}
          </div>
        </div>
      </section>,
    ],
    TabDataClass: "custom-tab container news-events-custom", // Add any custom CSS class here
  };

  if (isDataFetching) {
    return <Loader />
  }

  return (
    <div>
      <EventBanner
        isLocalBanner={true}
        localBanner="news-events.jpg"
        bannerTitle="News & Events"
      />
      <TabWrapper {...tabData} />
    </div>
  );
};

export default NewsAndEvents;
