import "bootstrap/dist/css/bootstrap.css";
import CommonButton from "../../component/common/Button/CommonButton";
import "../../styles.css";
import "../stem-shop/StemShop.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Card, CardImg, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import StemProductCard from "../../component/common/cards/StemProductCard";
import TabWrapper from "../../component/common/tab/TabWrapper";
import SliderCard from "../../component/common/cards/SliderCard";
import VideoTestim from "../../component/common/video-testimonial/VideoTestim";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getProducts,
  getRobotixProducts,
  getSpecificCategoryBlog,
} from "../../redux/apiCalls";
import { publicRequest } from "../../requestMethod";
import { More } from "@mui/icons-material";
import MiniLoader from "../../component/common/Loader/miniLoader/MiniLoader";
import { extractFirstParagraph, truncateText } from "../../config";
import { Helmet } from "react-helmet";

const StemShop = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getProducts(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getSpecificCategoryBlog(dispatch, "Parenting Tips");
  }, []);

  const { isFetching: isBlogsFetching, blogs } = useSelector(
    (state) => state.blog
  );
  const { isFetching: isRatingFetching, ratings } = useSelector(
    (state) => state.rating
  );

  const [ageGroup, setAgeGroup] = useState([]);
  const [tabAgeGroupTitles, setTabAgeGroupTitles] = useState([]);
  const getAgeGroups = async () => {
    try {
      const res = await publicRequest.get("/ageGroup/all");
      if (res?.status === 200) {
        setAgeGroup(res.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    getAgeGroups();
  }, [dispatch]);

  useEffect(() => {
    // Extract ageGroup property and update tabAgeGroupTitles
    setTabAgeGroupTitles(ageGroup.map((item) => "Age " + item.ageGroup));
  }, [ageGroup]);

  const roboProducts = useSelector((state) =>
    state.product?.products?.filter(
      (item) => item.isRobotixProduct && !item.isForSubscription
    )
  );

  const roboProductsWithSubscription = useSelector((state) =>
    state.product?.products?.filter(
      (item) => item.isRobotixProduct && item.isForSubscription
    )
  );


  const isFetching = useSelector((state) => state.product.isFetching);

  const user = useSelector((state) => state.loginUser.currentUser);

  // Load More
  const [displayIndices, setDisplayIndices] = useState({});

  // Define different card counts for tablet and non-tablet screens
  const defaultDisplayCountSS = window.innerWidth >= 992 ? 3 : 2;
  const loadMoreCountSS = window.innerWidth >= 992 ? 3 : 2;

  const handleLoadMore = (ageGroup) => {
    const currentDisplayIndex =
      displayIndices[ageGroup] || defaultDisplayCountSS;
    const newDisplayIndex = Math.min(
      currentDisplayIndex + loadMoreCountSS,
      roboProducts.length
    );
    setDisplayIndices({
      ...displayIndices,
      [ageGroup]: newDisplayIndex,
    });
  };

  const courses = ageGroup.map((item, key) => {
    const ageGroupDisplayIndex =
      displayIndices[item.ageGroup] || defaultDisplayCountSS;

    const ageGroupProducts = roboProducts.filter((product) =>
      product.ageGroup.some((age) => item.ageGroup.includes(age))
    );

    const shouldDisplayLoadMore =
      ageGroupDisplayIndex < ageGroupProducts.length;

    return (
      <div key={key}>
        {/* ... (your existing code for displaying products) */}

        <Container>
          <Row>
            {ageGroupProducts
              .slice(0, ageGroupDisplayIndex)
              .map((product, index) => {
                const ratingForCurrentProduct = ratings.filter(
                  (item) => item.productId === product._id
                );

                return (
                  <div className="col-lg-4 col-sm-6" key={index}>
                    <StemProductCard
                      key={index}
                      {...product}
                      customClass="free-cu-btn"
                    />
                  </div>
                )
              }
                //   (
                //   <div className="col-lg-4 col-sm-6" key={index}>
                //     <StemProductCard
                //       key={index}
                //       {...product}
                //       customClass="free-cu-btn"
                //     />
                //   </div>
                // )
              )}
          </Row>
        </Container>

        <div className="load-more-products ss-lm-btn text-center">
          {shouldDisplayLoadMore && (
            <button
              onClick={() => handleLoadMore(item.ageGroup)}
              className="btn btn-primary"
            >
              Load More
            </button>
          )}
        </div>
      </div>
    );
  });

  const responsiveConfigA = {
    0: {
      items: 1,
    },
    768: {
      items: 1,
    },
    992: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  };
  // Card Slider Gift Section Carousel Customizations
  const optionsA = {
    className: "owl-theme gift-cards-slider",
    loop: true,
    center: true,
    margin: 30,
    autoplay: true,
    smartSpeed: 450,
    nav: true,
    // navText: [<ArrowForwardIosIcon />],
    responsive: responsiveConfigA,
  };

  // Responsive breakpoint configuration for the carousel
  const responsiveConfigB = {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  };

  // Card Slider Section Carousel Customizations
  const optionsB = {
    className: "owl-theme blogs-card-slider",
    loop: true,
    center: true,
    margin: 30,
    autoplay: true,
    smartSpeed: 450,
    nav: true,
    dots: false,
    // navText: [<ArrowForwardIosIcon />],
    responsive: responsiveConfigB,
  };
  return (
    <div className="stem-shop-pg">
      {/* STEM Shop Page Banner  */}
      <Helmet>
        <title>Best Stem Science Kits | Educational Products | Meritus AI
        </title>
        <link rel="canonical" href="https://meritus.ai/stem-shop" />
        <meta name="description" content="We have the best stem science kits that teach many basic things to your kids. And we are the pioneers of the best educational products in India." />
      </Helmet>
      <Container fluid>
        <Row>
          <Col lg={12} className="px-0">
            <div className="stem-banner">
              <div className="banner-content-wrapper">
                <h1>
                  Discover the
                  <br />
                  <span>
                    Magic of <span className="custm-txt-l">Learning</span>
                  </span>
                  <br />
                  through
                  <span className="custm-txt-p">&nbsp;Play</span>
                </h1>
                <p>
                  Let your Child's Imagination soar as they Design, Create &
                  Conquer the world
                </p>
                {!user && (
                  <Link to="/register">
                    <CommonButton btnClass="stem-btn" btnName="Sign Up" />
                  </Link>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Subscription products  */}
      <section className="subscription-products pt-50">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-npgs dark-pink-head">
                <h2 className="m-0">Unleash the Power of STEM</h2>
              </div>
              <div className="sub-heading-npgs ss-sub-head">
                <p>
                  Play and Learn with our{" "}
                  <span>Monthly Subscription Kits!</span>
                </p>
              </div>
            </Col>
          </Row>

          {/* Subscription Product Courses */}
          <Row>
            {isFetching === true ? (
              <MiniLoader />
            ) : (
              roboProductsWithSubscription?.map((product, index) => {
                if (product.isRobotixProduct && product.isForSubscription) {
                  // const fetchedProduct = products.find(
                  //   (item) => item._id === product
                  // );
                  const ratingForCurrentProduct = ratings?.filter(
                    (item) => item.productId === product
                  );
                  // if (index <= 2) {
                    return (
                      <Col lg={4} key={index}>
                        {/* <StemProductCard {...product} customClass="free-cu-btn" /> */}
                        <StemProductCard
                          {...product}
                          ratingForCurrentProduct={ratingForCurrentProduct}
                          customClass="free-cu-btn"
                        />
                      </Col>
                    );
                  // }

                } else {
                  return null;
                }
              })
            )}
          </Row>
        </Container>
      </section>

      {/* Tab to filter STEM courses according to AGE  */}
      <Container>
        <Row>
          <TabWrapper
            titles={tabAgeGroupTitles}
            contents={courses}
            TabWrapperClass="stem-tab-wrapper"
            TabDataClass="stem-shop-tab lai-tab-wrapper"
          />
        </Row>
      </Container>

      {/* Video testimonial section */}
      <Container className="pb-30">
        <Row>
          <VideoTestim
            sectionHeading="What our learners are saying?"
            category="stemShop"
          />
        </Row>
      </Container>

      {/* Gift Option section  */}
      {/* <section className="card-slider pt-50 pb-30">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-npgs dark-pink-head pb-30">
                <h2>Gifting Options</h2>
              </div>
              <OwlCarousel {...optionsA}> */}
      {/* Gift card -1  */}
      {/* <SliderCard
                  CardImg="images/learn-ai/Gifting-option-1-meritus.jpg"
                  CardTitle="Lorem ipsum dolor sit amet"
                  CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
                /> */}

      {/* Gift card 2 */}
      {/* <SliderCard
                  CardImg="images/learn-ai/Gifting-option-2-meritus.jpg"
                  CardTitle="Lorem ipsum dolor sit amet"
                  CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
                /> */}

      {/* Gift card 3 */}
      {/* <SliderCard
                  CardImg="images/learn-ai/Gifting-option-3-meritus.jpg"
                  CardTitle="Lorem ipsum dolor sit amet"
                  CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
                /> */}

      {/* Gift card 4 */}
      {/* <SliderCard
                  CardImg="images/learn-ai/Gifting-option-4-meritus.jpg"
                  CardTitle="Lorem ipsum dolor sit amet"
                  CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
                /> */}

      {/* Gift card -5  */}
      {/* <SliderCard
                  CardImg="images/learn-ai/Gifting-option-1-meritus.jpg"
                  CardTitle="Lorem ipsum dolor sit amet"
                  CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
                />
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* slider cards Section  */}
      <section className="card-slider blog-slider pt-50 pb-30">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-npgs blog-sec-head dark-pink-head pb-30">
                <h2>
                  Parenting <span>Tips</span>
                </h2>
              </div>
              {isBlogsFetching ? (
                <MiniLoader />
              ) : (
                <OwlCarousel {...optionsB}>
                  {/* Blog card -1  */}
                  {blogs?.map((blog, index) => {
                    if (index < 10) {
                      return (
                        <SliderCard
                          CardImg={blog.blogImage}
                          CardTitleClass="blog-pink-head"
                          CardTitle={blog.title}
                          CardDesc={extractFirstParagraph(blog?.description)}
                        />
                      );
                    }
                  })}

                  {/* <SliderCard
                      CardImg="images/stem/blog-a.png"
                      CardTitleClass="blog-pink-head"
                      CardTitle="Read other blogs here"
                      CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
                    /> */}
                  <div className="slider-card-container">
                    <Card className="slider-card ">
                      {/* card Image */}
                      <Link to={`/category-blog/Parenting Tips`}>
                        <CardImg
                          className="sc-img"
                          variant="top"
                          src="images/stem/blog-a.png"
                        />
                      </Link>
                      {/* Card Body  */}
                      <Card.Body>
                        <Card.Title className={`slider-card-name`}>
                          <Link
                            to={`/category-blog/Parenting Tips`}
                            target="_blank"
                          >
                            <h3>{truncateText("Read other blogs here", 30)}</h3>
                          </Link>
                        </Card.Title>
                        <Card.Text className="slider-card-desc">
                          {/* <p>{}</p> */}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </OwlCarousel>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default StemShop;
