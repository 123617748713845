import "./home.css";
import Banner from "../component/home/Banner";
import Card from "../component/Card.jsx";
import Card2 from "../component/Card2.jsx";
import Trainers from "../component/Trainers.jsx";
import About from "../component/home/About";
import Computational from "../component/home/Computational.jsx";
import TestimonialCarousel from "../component/home/TestimonialCarousel";
import ExploreMore from "../component/ExploreMore.jsx";
import Cta from "../component/home/Cta.jsx";
import ContactUs from "../component/home/ContactUs";
import Footer from "../component/Footer";
import ExploreCards from "../component/ExploreCards";
import Featured from "../component/home/Featured";
import Gallery from "../component/Gallery";
import Trainer from "../component/home/Trainer";
import TabWrapper from "../component/common/tab/TabWrapper";
import Owll from "../component/Owll";
import LogoSlider from "../component/common/logoSlider/LogoSlider";
import "../component/common/tab/TabWrapperone.css";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SliderCard from "../component/common/cards/SliderCard";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import VideoTestim from "../component/common/video-testimonial/VideoTestim";
import StemProductCard from "../component/common/cards/StemProductCard";
import LAIProductCard from "../component/common/cards/LAIProductCard";
import AwardSection from "../component/home/AwardSection";
import {
  getAllBlogCategory,
  getAllBlogs,
  getAllCustomSections,
  getAllRatings,
  getProducts,
} from "../redux/apiCalls";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { publicRequest } from "../requestMethod";
import { async } from "@vimeo/player";
import { Link } from "react-router-dom";
import AllBlogs from "../component/allblogs/AllBlogs.jsx";
import MiniLoader from "../component/common/Loader/miniLoader/MiniLoader.jsx";
import FlipBook from "./flipbook/FlipBook.jsx";
import { Helmet } from "react-helmet";


// Responsive breakpoint configuration for the carousel
const responsiveConfig = {
  0: {
    items: 1,
  },
  768: {
    items: 1,
  },
  992: {
    items: 2,
  },
  1200: {
    items: 3,
  },
};

// Card Slider Section Carousel Customizations
const options = {
  className: "owl-theme gift-cards-slider",
  loop: true,
  center: true,
  margin: 30,
  autoplay: true,
  smartSpeed: 450,
  nav: false,
  // navText: [<ArrowForwardIosIcon />],
  responsive: responsiveConfig,
};

export default function Home() {
  const { isFetching: isProductFetching, products } = useSelector(
    (state) => state.product
  );
  const { isFetching: isRatingFetching, ratings } = useSelector(
    (state) => state.rating
  );

  // Calculate the sum of ratings
  // const totalRating = ratings?.reduce((acc, curr) => acc + curr.rating, 0);

  // Calculate the average rating
  // const averageRating = totalRating / ratings?.length;

  const getProductRatings = (productId) => {};

  const [ageGroup, setAgeGroup] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllBlogs(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getAllBlogCategory(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getProducts(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getAllCustomSections(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getAllRatings(dispatch);
  }, [dispatch]);

  const getAgeGroups = async () => {
    try {
      const res = await publicRequest.get("/ageGroup/all");
      if (res?.status === 200) {
        setAgeGroup(res.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAgeGroups();
  }, []);

  const blogs = useSelector((state) =>
    state.blog.blogs.filter((item) => item.status == true)
  );
  const blogCategories = useSelector(
    (state) => state.blogCategory.blogCategory
  );

  const mergedBlogs =
    blogs &&
    blogs.map((blog) => {
      const matchingCategory =
        blogCategories &&
        blogCategories.find((category) => category.category === blog.category);
      if (matchingCategory) {
        return { ...blog, blogCategoryImage: matchingCategory.categoryImage };
      }
      return blog;
    });

  const currentBlogCategories =
    mergedBlogs &&
    mergedBlogs.map((item) => {
      let allCategory = [];
      allCategory.push(item.category);
      return allCategory;
    });

  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    if (mergedBlogs.length > 0) {
      setSelectedCategory(mergedBlogs[0].category);
    }
  }, []);

  useEffect(() => {
    const filteredBlogs = selectedCategory
      ? mergedBlogs.filter((blog) => blog.category === selectedCategory)
      : mergedBlogs;
    setFilteredBlogs(filteredBlogs);
  }, [selectedCategory]);

  // const filteredBlogs = selectedCategory ? mergedBlogs.filter(blog => blog.category === selectedCategory) : mergedBlogs;

  const state = useSelector((state) => state);

  console.log("state ==>", state)

  const cardData = [
    {
      title: "Empowering Lifelong Learning",
      imageUrl: "images/home/card-1.jpg",
      backgroundColor: "rgb(255, 117, 179)",
      customClass: "custm-ptb",
    },
    {
      title: "Nurturing Future Innovators",
      imageUrl: "images/home/card-2.jpg",
      backgroundColor: "rgb(254, 188, 8)",
      customClass: "custm-ptb",
    },
    {
      title: "Revolutionizing Learning through Technology",
      imageUrl: "images/home/card-3.jpg",
      backgroundColor: "#a6cb46",
    },
    // {
    //   imageUrl: "images/home/card-4.png",
    //   backgroundColor: "#FFF1F9",
    // },
  ];

  const trainersData = [
    {
      Trainerstitle: "Rachelle Dene",
      TrainersPara: "ISET Certified Educator, Speaker & Author",
      TrainersimageUrl: "images/home/trainers-1.jpg",
      backgroundColor: "#2ECC40",
      TrainersCourse: "Virtual Driverless",
      Trainersrating: "★★★★★",
    },
    {
      Trainerstitle: "Rachelle Dene",
      TrainersPara: "ISET Certified Educator, Speaker & Author",
      TrainersimageUrl: "images/home/trainers-2.jpg",
      TrainersCourse: "AI Novus",
      Trainersrating: "★★★★★",
    },
    {
      Trainerstitle: "Rachelle Dene",
      TrainersPara: "ISET Certified Educator, Speaker & Author",
      TrainersimageUrl: "images/home/trainers-3.jpg",
      backgroundColor: "#2ECC40",
      TrainersCourse: "AI Primus",
      Trainersrating: "★★★★★",
    },
    {
      Trainerstitle: "Rachelle Dene",
      TrainersPara: "ISET Certified Educator, Speaker & Author",
      TrainersimageUrl: "images/home/trainer-4.jpg",
      backgroundColor: "#2ECC40",
      TrainersCourse: "AI Meritus",
      Trainersrating: "★★★★★",
    },
  ];
  const logos = [
    "images/home/partners/client-1.png",
    "images/home/partners/client-2.png",
    "images/home/partners/client-3.png",
    "images/home/partners/client-4.png",
    "images/home/partners/client-5.png",
    "images/home/partners/client-6.png",
    "images/home/partners/client-7.png",
    "images/home/partners/client-8.png",
    "images/home/partners/client-9.png",
    "images/home/partners/client-10.png",
    "images/home/partners/client-11.png",
    "images/home/partners/client-12.png",
    "images/home/partners/client-13.png",
    "images/home/partners/client-14.png",
    "images/home/partners/client-15.png",
    "images/home/partners/client-16.png",
    "images/home/partners/client-17.png",
    "images/home/partners/client-18.png",
    "images/home/partners/client-19.png",
    "images/home/partners/client-20.png",
    "images/home/partners/client-21.png",
    "images/home/partners/client-22.png",
    "images/home/partners/client-23.png",
    "images/home/partners/client-24.png",
    "images/home/partners/client-25.png",
  ];
  const responsiveConfig = {
    0: {
      items: 2, // Show 1 item on screens between 0px and 767px (inclusive)
    },
    768: {
      items: 2, // Show 3 items on screens between 768px and 991px (inclusive)
    },
    992: {
      items: 4, // Show 4 items on screens between 992px and 1199px (inclusive)
    },
    1200: {
      items: 4, // Show 5 items on screens larger than 1200px
    },
  };

  // Responsive breakpoint configuration for the carousel
  const responsiveConfigForBestSeller = {
    0: {
      items: 1, // Show 1 item on screens between 0px and 767px (inclusive)
    },
    768: {
      items: 2, // Show 3 items on screens between 768px and 991px (inclusive)
    },
    992: {
      items: 2, // Show 4 items on screens between 992px and 1199px (inclusive)
    },
    1200: {
      items: 3, // Show 5 items on screens larger than 1200px
    },
  };

  const titles = [
    // <div>
    //   4-6 Yr <br />
    //   ROBOTIX KITS
    // </div>,
    <div>
      Ages <br /> 7-10
    </div>,
    <div>
      Ages <br />
      11-13 Ages <br />
    </div>,
    <div>
      Ages <br />
      14-18 Ages <br />
    </div>,
    <div>
      18+ COLLEGES
      <br />& PROFESSIONALS
    </div>,
  ];

  let contents = [];

  ageGroup.map((item, key) => {
    contents.push(
      <section key={key}>
        <div className="container">
          <div className="row">
            {products.length > 0 &&
              products.map((product, index) => {
                return (
                  item.ageGroup === product.ageGroup &&
                  product.status == true &&
                  product.courseType !== "robotix" && (
                    <div className="col-lg-4 col-md-6" key={index}>
                      <Card2 key={index} {...product} />
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </section>
    );
  });

  const videoTestimonials = [
    {
      id: 1,
      thumbnail: "/video/thumbnails/Learners-1.jpg",
      url: "https://vimeo.com/898546304/12b4621e16?share=copy",
    },
    {
      id: 2,
      thumbnail: "/video/thumbnails/Learners-2.jpg",
      url: "https://vimeo.com/898546327/533efdd034?share=copy",
    },
    {
      id: 3,
      thumbnail: "/video/thumbnails/Learners-3.jpg",
      url: "https://vimeo.com/898546355/b5ddf2ccce?share=copy",
    },
    {
      id: 4,
      thumbnail: "/video/thumbnails/Learners-3.jpg",
      url: "https://vimeo.com/898546386/ebe61ccaec?share=copy",
    },
  ];

  const customSections = useSelector((state) => state.customSection.sections);

  return (
    <div>
      <Helmet>
        <title>Best Institute For Artificial Intelligence In Chennai | Meritus AI</title>
        <meta name="description" content="We are one of the best institute in chennai to provide AI courses, ML courses, Data science and robotics courses from age 7 to 14 +." />
        <link rel="canonical" href="https://meritus.ai/" />
      </Helmet>
     
      {/* <Header /> */}
      <Banner />
      {/* Card 1 */}
      <section className="card1 pt-100">
        <div className="container">
          <div className="row justify-content-center">
            {cardData.map((card, index) => (
              <div
                className={`col-6 col-md-4 col-lg-4 heighlight-${index} ${
                  card.customClass || ""
                }`}
                key={index}
              >
                <Card {...card} isPadding={index < 2} />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Render the Flipbook component */}
      {/* <FlipBook totalPages={20} /> */}

      {/* Our Best Sellers  */}
      {/* <section className="subscription-products pt-50">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-npgs dark-pink-head">
                <h2 className="m-0">Our Best Sellers</h2>
              </div>
              <div className="sub-heading-npgs ss-sub-head"></div>
              <div className="featured-container">
                <div className="container">
                  {isProductFetching ? (
                    <MiniLoader />
                  ) : (
                    <OwlCarousel
                      className="owl-theme"
                      margin={30}
                      nav
                      responsive={responsiveConfigForBestSeller}
                    >
                      {products.length > 0 &&
                        products
                          .filter((product) => product.bestSeller === true)
                          .map((currentProduct, index) => {
                            const ratingForCurrentProduct = ratings.filter(
                              (item) => item.productId === currentProduct._id
                            );

                            return (
                              <div className="item px-2" key={index}>
                                {currentProduct?.isRobotixProduct ? (
                                  <StemProductCard
                                    {...currentProduct}
                                    ratingForCurrentProduct={
                                      ratingForCurrentProduct
                                    }
                                    customClass="free-cu-btn"
                                  />
                                ) : (
                                  <LAIProductCard
                                    {...currentProduct}
                                    ratingForCurrentProduct={
                                      ratingForCurrentProduct
                                    }
                                    SpcCustomClass="buy-yellow-btn"
                                  />
                                )}
                              </div>
                            );
                          })}
                    </OwlCarousel>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* Popular online courses  */}
      {customSections?.map((customSection, sectionKey) => {
        return (
          <section className="subscription-products pt-50" key={sectionKey}>
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="heading-npgs dark-pink-head">
                    <h2
                      className="m-0 pb-20 text-capitalize"
                      style={
                        sectionKey % 2 === 0
                          ? { color: "var(--dPink)" }
                          : { color: "var(--dBlue)" }
                      }
                    >
                      {customSection?.sectionTitle}
                    </h2>
                  </div>
                  <div className="sub-heading-npgs ss-sub-head"></div>
                </Col>
              </Row>

              <Row>
                {isProductFetching ? (
                  <MiniLoader />
                ) : (
                  // <p></p>
                  customSection?.products.map((product, productKey) => {
                    // Find the product in the products array based on the product ID
                    const fetchedProduct = products.find(
                      (item) => item._id === product
                    );
                    const ratingForCurrentProduct = ratings?.filter(
                      (item) => item.productId === product
                    );
                    // Render the product card if found
                    if (fetchedProduct) {
                      return (
                        <Col lg={4} md={6} key={productKey}>
                          {fetchedProduct.isRobotixProduct ? (
                            <StemProductCard
                              {...fetchedProduct}
                              ratingForCurrentProduct={ratingForCurrentProduct}
                              customClass="free-cu-btn"
                            />
                          ) : (
                            <LAIProductCard
                              {...fetchedProduct}
                              ratingForCurrentProduct={ratingForCurrentProduct}
                              SpcCustomClass="buy-yellow-btn"
                            />
                          )}
                        </Col>
                      );
                    } else null;
                  })
                )}
              </Row>
            </Container>
          </section>
        );
      })}

      <About />
      {/* <section className="our_partners pt-100 pb-50">
        <h2 className="inh2 text-center">
          Schools we have <span>worked with</span>
        </h2>
        <LogoSlider logos={logos} responsiveConfig={responsiveConfig} />
      </section>
      <Computational />
      <div className="container">
        <div className="card2">
          <h2 className="inh2">
            Explore our <span> courses</span>
          </h2>
          <h2 className="subHeading ">
            It's Never Too Early - <span>Never Too Late</span>
          </h2>
          <TabWrapper
            titles={titles}
            TabDataClass={"custom-tab homepage-tab"}
            contents={contents}
          />
        </div>
      </div> */}
      {/* Featured Programmes */}
      {/* <div className="card3 mt-100">
        <h2 className="pt-100 inh2">
          Our <span>Bestsellers</span>
        </h2>
        <Featured />
      </div>
      <TestimonialCarousel />
      <section>
        <div className="shop_now">
          <h2 className="pb-50 pt-100 text-center inh2">
            Gift <span>Now</span>
          </h2>
          <div className="container">
            <div className="row">
              <div className="col-md-4 mb-3 mb-md-0">
                <Trainers
                  Trainerstitle="Gift Now"
                  TrainersimageUrl={`${process.env.PUBLIC_URL}/images/home/Gift-Now.jpg`}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <Trainers
                  Trainerstitle="Enroll Now"
                  TrainersimageUrl={`${process.env.PUBLIC_URL}/images/home/Enrol-Now.jpg`}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <Trainers
                  Trainerstitle="Shop Kits"
                  TrainersimageUrl={`${process.env.PUBLIC_URL}/images/home/shop-2.jpg`}
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Gift Options Section  */}
      {/* slider cards Section  */}
      {/* Video testimonial section  */}
      <section className="homepag-video-testim mt-50 pt-50 pb-50">
        <div className="container">
          <VideoTestim
            className="video-testim-wrapper pt-50"
            sectionHeading={"Hear from Meritus Parents"}
            videos={videoTestimonials}
            category="home"
          />
        </div>
      </section>

      <section className="pt-100">
        <div className="container">
          <div className="row">
            {/* Each AwardSection component in its own column */}
            <div className="col-md-4">
              <AwardSection awardImage="images/home/award/Awards-GIF .gif" />
            </div>
            <div className="col-md-4">
              <AwardSection awardImage="images/home/award/Awards-2.png" />
            </div>
            <div className="col-md-4">
              <AwardSection awardImage="images/home/award/Cwrd-funding.gif" />
            </div>

            {/* Add more AwardSection components in col-md-4 if needed */}
          </div>
        </div>
      </section>

      {/* Gifting options  */}
      {/* <section className="card-slider pt-50 pb-30">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-npgs dark-pink-head pb-30">
                <h2>Gifting Options</h2>
              </div>
              <OwlCarousel {...options}> */}
      {/* Gift card -1  */}
      {/* <SliderCard
                  CardImg="images/learn-ai/Gifting-option-1-meritus.jpg"
                  CardTitle="Lorem ipsum dolor sit amet"
                  CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
                /> */}

      {/* Gift card 2 */}
      {/* <SliderCard
                  CardImg="images/learn-ai/Gifting-option-2-meritus.jpg"
                  CardTitle="Lorem ipsum dolor sit amet"
                  CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
                /> */}

      {/* Gift card 3 */}
      {/* <SliderCard
                  CardImg="images/learn-ai/Gifting-option-3-meritus.jpg"
                  CardTitle="Lorem ipsum dolor sit amet"
                  CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
                /> */}

      {/* Gift card 4 */}
      {/* <SliderCard
                  CardImg="images/learn-ai/Gifting-option-4-meritus.jpg"
                  CardTitle="Lorem ipsum dolor sit amet"
                  CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
                /> */}

      {/* Gift card -5  */}
      {/* <SliderCard
                  CardImg="images/learn-ai/Gifting-option-1-meritus.jpg"
                  CardTitle="Lorem ipsum dolor sit amet"
                  CardDesc="Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor"
                />
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* blog section */}
      <section id="exploreBlogs" className="explore_container pb-50">
        <div className="container">
          <h2 className="pb-20 pt-50 inh2 text-center">
            Explore<span> New Frontiers</span>
          </h2>
          {/* <div className="row">
            {blogCategories &&
              blogCategories.map((blog) => {
                return (
                  <div
                    className="col-6 col-md-4 mb-3"
                    key={blog.category} // Add a unique key for each category
                    onClick={() => handleCategoryClick(blog.category)}
                  >
                    <ExploreMore
                      title={blog.category}
                      imgUrl={blog.categoryImage}
                      categoryId={blog._id}
                    />
                  </div>
                );
              })}
          </div> */}
          <AllBlogs />
        </div>
      </section>

      <Cta />

      {/* contact us */}
      {/* <section className="contact_us pb-50">
        <div className="container">
          <ContactUs />
        </div>
      </section> */}
      {/* <Footer /> */}
    </div>
  );
}
