// import React from "react";
import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import "../../component/TeacherPdp/SchoolForm.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { validateRequired } from "../../formValidation";
import { publicRequest } from "../../requestMethod";
import { countriesListWithCode } from "../../config";

const BundleForm = () => {
  const [attendees, setAttendees] = useState(0);
  const [anotherValue, setAnotherValue] = useState(0);
  const [schoolName, setSchoolName] = useState(null);
  const [principleName, setPrincipleName] = useState(null);
  const [countryCode, setCountryCode] = useState("+91");
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [schoolAddress, setSchoolAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  const submitFormQuery = async (user) => {
    try {
      setIsLoaderActive(true);
      const res = await publicRequest.post("school-form/create", user);
      if (res && res.status === 200) {
        setIsLoaderActive(false);
        setSuccess(true);
        setSuccessMessage("We have recieved your query.");
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
      }
    } catch (error) {
      setIsLoaderActive(false);
      setError(true);
      setErrorMessage("Something went wrong!");
    }
  };

  // Validate function for form validation start =======
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    const schoolNameValidErr = validateRequired(schoolName);
    const principleNameValidErr = validateRequired(principleName);
    const phoneValidErr = validateRequired(phone, "phone");
    const schoolAddressValidErr = validateRequired(schoolAddress);
    const cityValidErr = validateRequired(city);
    const emailValidErr = validateRequired(email);
    const stateValidErr = validateRequired(state);
    const countryValidErr = validateRequired(country);

    if (schoolNameValidErr) {
      formIsValid = false;
      newErrors.schoolName = schoolNameValidErr;
    }
    if (principleNameValidErr) {
      formIsValid = false;
      newErrors.principleName = principleNameValidErr;
    }
    if (emailValidErr) {
      formIsValid = false;
      newErrors.email = emailValidErr;
    }
    if (phoneValidErr) {
      formIsValid = false;
      newErrors.phone = phoneValidErr;
    }
    if (schoolAddressValidErr) {
      formIsValid = false;
      newErrors.schoolAddress = schoolAddressValidErr;
    }
    if (cityValidErr) {
      formIsValid = false;
      newErrors.city = cityValidErr;
    }
    if (countryValidErr) {
      formIsValid = false;
      newErrors.country = countryValidErr;
    }
    if (stateValidErr) {
      formIsValid = false;
      newErrors.state = stateValidErr;
    }

    setErrors(newErrors);
    return formIsValid;
  };
  // Validate function for form validation end======

  const submitData = async () => {
    setSuccess(false);
    setError(false);
    if (validateForm()) {
      submitFormQuery({
        schoolName,
        principleName,
        email,
        countryCode,
        phone,
        schoolAddress,
        city,
        state,
        country,
        message,
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && validateForm()) {
      submitData();
    }
  };

  return (
    <div className="container mt-5 school_form_container" id="pdp_form">
      <h2 className="inb text-center">
        Enquire <span>Now!</span>
      </h2>

      <form>
        <div className="mb-3 row">
          <label
            htmlFor="schoolName"
            className="d-flex justify-content-end col-sm-3 col-form-label"
          >
            School Name*
          </label>
          <div className="col-sm-7">
            <input
              type="text"
              id="schoolName"
              className="form-control"
              value={schoolName}
              onChange={(e) => setSchoolName(e.target.value)}
            />
            <span className="error-text">{errors.schoolName}</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="principalName"
            className="d-flex justify-content-end  col-sm-3 col-form-label"
          >
            Principal/Correspondent Name*
          </label>
          <div className="col-sm-7">
            <input
              type="text"
              id="principalName"
              className="form-control"
              value={principleName}
              onChange={(e) => setPrincipleName(e.target.value)}
            />
            <span className="error-text">{errors.principleName}</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="principalPhone"
            className="d-flex justify-content-end col-sm-3 col-form-label"
          >
            Principal Phone No*
          </label>
          <div className="col-sm-2">
            <select
              id="countryCode"
              value={countryCode}
              className="form-control"
              onChange={(e) => setCountryCode(e.target.value)}
            >
              {
                countriesListWithCode.map((country) => {
                  return (
                    <option value={country.code}>{country.code}</option>
                  )
                })
              }
            </select>
          </div>

          <div className="col-sm-5">
            <input
              type="text"
              id="principalPhone"
              className="form-control"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <span className="error-text">{errors.phone}</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="principalEmail"
            className=" d-flex justify-content-end col-sm-3 col-form-label"
          >
            Principal Email ID*
          </label>
          <div className="col-sm-7">
            <input
              type="email"
              id="principalEmail"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <span className="error-text">{errors.email}</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label className="col-sm-3 col-form-label d-flex justify-content-end ">
            School Address
          </label>
          <div className="col-sm-7">
            <input
              type="text"
              className="form-control mb-2"
              placeholder=""
              value={schoolAddress}
              onChange={(e) => setSchoolAddress(e.target.value)}
            />
            <span className="error-text">{errors.schoolAddress}</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="city"
            className="col-sm-3 col-form-label d-flex justify-content-end "
          >
            City*
          </label>
          <div className="col-sm-3">
            <input
              type="text"
              id="city"
              className="form-control"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <span className="error-text">{errors.city}</span>
          </div>
          <label
            htmlFor="state"
            className="col-sm-1 col-form-label d-flex justify-content-center"
          >
            State
          </label>
          <div className="col-sm-3">
            <input
              type="text"
              id="state"
              className="form-control"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            <span className="error-text">{errors.state}</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="country"
            className="col-sm-3 col-form-label d-flex justify-content-end "
          >
            Country*
          </label>
          <div className="col-sm-7">
            <input
              type="text"
              id="country"
              className="form-control"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
            <span className="error-text">{errors.country}</span>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="country"
            className="col-sm-3 col-form-label d-flex justify-content-end "
          >
            Comment*
          </label>
          <div className="col-sm-7">
            <textarea
              type="text"
              id="message"
              rows="4"
              className="form-control"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <span className="error-text">{errors.message}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            {success ? (
              <div className="alert alert-success">{successMessage}</div>
            ) : (
              error && <div className="alert alert-danger">{errorMessage}</div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 text-center school_form_submit">
            <button
              type="button"
              className={`${
                isLoaderActive ? "button-loader load" : "submit-coupon"
              }`}
              onClick={submitData}
            >
              <span>Click to Submit</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BundleForm;
