import React, { useState } from "react";
import "./Quiz.css";
import { AiFillCloseCircle } from "react-icons/ai";
import RenderHTMLContent from "../RenderHTMLContent";

const Quiz = ({ quizData, onClose, updateProgress, chapterIndex, resourceIndex }) => {

  const [currentStep, setCurrentStep] = useState("instructions");
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const [activeQuiz, setActiveQuiz] = useState(false);

  const calculateScore = () => {
    let score = 0;
    quizData.questions.forEach((question, index) => {
      if (userAnswers[index] == question.correctAnswer) {
        score++;
      }
    });
    return score;
  };

  const handleAnswerOptionClick = (selectedAnswer) => {
    // setUserAnswers([...userAnswers, selectedAnswer]);
    setUserAnswers((prevAnswer) => {
      const updatedAnswers = [...prevAnswer];
      updatedAnswers[currentQuestion] = selectedAnswer;
      return updatedAnswers;
    })

    // if (selectedAnswer == quizData.questions[currentQuestion].correctAnswer) {
    //   setScore((prevScore) => prevScore + 1);
    // } else {
    // }

    // if (selectedAnswer == quizData.questions[currentQuestion].correctAnswer) {
    //   setScore(score + 1);
    // } else if (score > 0) {
    //   setScore(score - 1)
    // }
    // if (selectedAnswer == quizData.questions[currentQuestion].correctAnswer) {
    //   if (userAnswers[currentQuestion] !== selectedAnswer) {
    //     setScore(score + 1);
    //   }
    // } else if (score > 0) {
    //   setScore(score - 1)
    // }

    // if (selectedAnswer === quizData.questions[currentQuestion].correctAnswer) {
    //   if (userAnswers[currentQuestion] != selectedAnswer) {
    //     setScore(score + 1);
    //   }
    // } else {
    //   if (userAnswers[currentQuestion] == quizData.questions[currentQuestion].correctAnswer) {
    //     setScore(score - 1);
    //   }
    // }
  };

  // Old working function ===============

  // const handleAnswerOptionClick = (selectedAnswer) => {
  //   // setUserAnswers([...userAnswers, selectedAnswer]);
  //   setUserAnswers((prevAnswer) => {
  //     const updatedAnswers = [...prevAnswer];
  //     updatedAnswers[currentQuestion] = selectedAnswer;
  //     return updatedAnswers;
  //   })

  //   // if (selectedAnswer == quizData.questions[currentQuestion].correctAnswer) {
  //   //   setScore((prevScore) => prevScore + 1);
  //   // } else {
  //   // }

  //   // if (selectedAnswer == quizData.questions[currentQuestion].correctAnswer) {
  //   //   setScore(score + 1);
  //   // } else if (score > 0) {
  //   //   setScore(score - 1)
  //   // }
  //   // if (selectedAnswer == quizData.questions[currentQuestion].correctAnswer) {
  //   //   if (userAnswers[currentQuestion] !== selectedAnswer) {
  //   //     setScore(score + 1);
  //   //   }
  //   // } else if (score > 0) {
  //   //   setScore(score - 1)
  //   // }

  //   // if (selectedAnswer === quizData.questions[currentQuestion].correctAnswer) {
  //   //   if (userAnswers[currentQuestion] != selectedAnswer) {
  //   //     setScore(score + 1);
  //   //   }
  //   // } else {
  //   //   if (userAnswers[currentQuestion] == quizData.questions[currentQuestion].correctAnswer) {
  //   //     setScore(score - 1);
  //   //   }
  //   // }
  // };

  const handleNextStep = async () => {
    if (currentStep === "instructions") {
      setCurrentStep("questions");
    } else if (currentStep === "questions") {
      const newProgress = 100;
      setCurrentStep("thankYou");
      setShowScore(true);
      setShowThankYou(true);
      const finalScore = calculateScore();
      setScore(finalScore);
      await updateProgress(chapterIndex, resourceIndex, newProgress);
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
  };

  const handlePrevQuestion = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  const handleClosePopup = () => {
    setCurrentStep("instructions");
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
    setShowThankYou(false);
    setUserAnswers([]);
    onClose();
  };

  return (
    <div className="quiz-popup-container">
      <AiFillCloseCircle className="close-quiz-btn" onClick={onClose} />
      {currentStep === "instructions" && (
        <div className="instructions-section">
          <h3 className="text-capitalize">quiz instructions</h3>
          <p>
            Welcome to the Ultimate Quiz Challenge! Test your knowledge on a
            wide range of topics with this exciting quiz. Get ready to answer
            questions that will challenge your intellect and have fun along the
            way. Remember, there are no limitations to learning, so embrace the
            opportunity to expand your horizons.
          </p>
          <ul>
            <li style={{ color: "var(--dPink)!important" }}>
              Read the Instructions Carefully:
            </li>
            <li>Read each question carefully before selecting your answer.</li>
            <li>Choose the best option that you think is correct.</li>
            <li>
              Each correct answer earns you points, so aim for a perfect score!
            </li>
            <li>
              There's no time limit, so take your time and think it through.
            </li>
            <li>Enjoy the quiz and have a great time!</li>
          </ul>
          <button className="next-button" onClick={handleNextStep}>
            Start Quiz
          </button>
        </div>
      )}

      {currentStep === "questions" && (
        <div className="quiz-container">
          <div className="question-section">
            <div className="question-count">
              Question {currentQuestion + 1}/{quizData.questions.length}
            </div>
            <div className="question-text">
              {/* {RenderHTMLContent(quizData.questions[currentQuestion].title)} */}
              <RenderHTMLContent
                isItQuestion={true}
                htmlContent={quizData.questions[currentQuestion].title}
              />
            </div>
          </div>
          <div className="answer-section">
            {quizData.questions[currentQuestion].options.map(
              (option, index) => (
                <label
                  key={index}
                  className={`answer-button ${userAnswers.includes(option) ? "selected" : ""
                    }`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    name={`question-${currentQuestion}`}
                    checked={userAnswers[currentQuestion] === option}
                    onChange={() => handleAnswerOptionClick(option)}
                    // checked={userAnswers[currentQuestion] === index + 1}
                    // onChange={() => handleAnswerOptionClick(index + 1)}
                  />
                  <span style={{ marginRight: "5px" }}>{`${String.fromCharCode(97 + index)}.`}</span>
                  {<RenderHTMLContent isItQuestion={true} htmlContent={option} />}
                </label>
              )
            )}
          </div>

          <div className="button-section">
            {currentQuestion > 0 && (
              <button className="prev-button" onClick={handlePrevQuestion}>
                Prev
              </button>
            )}
            {currentQuestion < quizData.questions.length - 1 ? (
              <button className="next-button" onClick={handleNextQuestion}>
                Next
              </button>
            ) : (
              <button className="submit-button" onClick={handleNextStep}>
                Submit
              </button>
            )}
          </div>
        </div>
      )}

      {currentStep === "thankYou" && (
        <div className="score-section">
          <div className="thank-you-message">
            Thank you for completing the quiz!
          </div>
          <div className="final-score">
            Your final score is {score} out of{" "}
            {quizData && quizData.questions.length}
          </div>
          {/* <button className="close-button" onClick={handleClosePopup}>
              Close
            </button> */}
        </div>
      )}
    </div>
  );
};

export default Quiz;

// import React, { useState } from 'react'

// const Quiz = ({ quizData, onClose }) => {
//   const [userAnswers, setUserAnswers] = useState({});
//   // Other state variables for tracking user input and result

//   // Function to handle user answer selection
//   const handleAnswerSelect = (questionIndex, selectedOptionIndex) => {
//     setUserAnswers(prevAnswers => ({
//       ...prevAnswers,
//       [questionIndex]: selectedOptionIndex,
//     }));
//   };

//   // Calculate and display result
//   // ...

//   return (
//     <div className="quiz-popup-container">
//       {quizData.questions.map((question, index) => (
//         <div key={index} className="question-container">
//           <h3>{question.question}</h3>
//           {question.options.map((option, optionIndex) => (
//             <label key={optionIndex}>
//               <input
//                 type="radio"
//                 value={optionIndex}
//                 checked={userAnswers[index] === optionIndex}
//                 onChange={() => handleAnswerSelect(index, optionIndex)}
//               />
//               {option}
//             </label>
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default Quiz
