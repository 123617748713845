// import { createSlice } from "@reduxjs/toolkit";

// const addToCartReducer = createSlice({
//     name: 'cart',
//     initialState: {
//         cartItems: [],
//         total: 0,
//         totalInDollar: 0,
//         quantity: 0,
//         discountedAmount: 0, // The cart amount after applying discounts
//         quantity: 0,
//         appliedCoupon: '',
//         discountType: '',
//         discount: 0,
//     },

//     reducers: {
//         // action to get all Cart products
//         getCartProductStart: (state) => {
//             state.isFetching = true;
//         },

//         getCartProductSuccess: (state, action) => {
//             state.isFetching = false;
//             state.cartItems = action.payload;
//         },

//         getCartProductFailure: (state) => {
//             state.isFetching = false;
//             state.error = true;
//         },

//         // // add to cart actions
//         addToCartStart: (state) => {
//             // state.isFetching = true;
//         },

//         // ==========******************* Old method when not storing in database **********==============
//         addToCartSuccess: (state, action) => {
//             state.isFetching = false;
//             const newItem = action.payload;
//             const existingItem = state.cartItems.find((item) => item._id === newItem._id);

//             // if (existingItem) {
//             //     // If the item already exists in the cart, update its quantity
//             //     existingItem.quantity += newItem.quantity;
//             //     state.total += newItem.productId.price * newItem.quantity;
//             //     state.totalInDollar += newItem.productId.priceInDollar * newItem.quantity;
//             //     state.quantity += newItem.quantity;
//             // } else {
//             //     // If the item is not already in the cart, add it as a new item
//             //     state.cartItems = [...state.cartItems, newItem];
//             //     // state.cartItems.push(newItem);
//             //     state.total += newItem.productId.price * newItem.quantity;
//             //     state.totalInDollar += newItem.productId.priceInDollar * newItem.quantity;
//             //     state.quantity += newItem.quantity;
//             // }
//             state.cartItems = [...state.cartItems, newItem];
//             // state.cartItems.push(newItem);
//             // state.total += newItem.productId.price * newItem.quantity;
//             // state.totalInDollar += newItem.productId.priceInDollar * newItem.quantity;
//             // state.quantity += newItem.quantity;
//         },

//         // addToCartSuccess: (state, action) => {
//         //     const newItem = action.payload;
//         //     state.isFetching = false;


//         //     // If the item is not already in the cart, add it as a new item
//         //     state.cartItems = [...state.cartItems, newItem];
//         //     // state.cartItems.push(newItem);
//         //     state.total += newItem.productId.price * newItem.quantity;
//         //     state.totalInDollar += newItem.productId.priceInDollar * newItem.quantity;
//         //     state.quantity += newItem.quantity;
//         //     // Update the cart item in the state based on cartItemId
//         //     // state.cartItems = state.cartItems.map(cartItem => {
//         //     //     // If cartId matches newItem.cartItemId, replace it with newItem
//         //     //     if (cartItem._id === newItem.cartItemId) {
//         //     //         state.total += newItem.price * newItem.quantity;
//         //     //         state.totalInDollar += newItem.priceInDollar * newItem.quantity;
//         //     //         return newItem;
//         //     //     }
//         //     //     // Otherwise, return the original cartItem
//         //     //     return cartItem;
//         //     // });
//         // },


//         // updateCartSuccess: (state, action) => {
//         //     state.isFetching = false;
//         //     const comingItem = action.payload;
//         //     // const existingItem = state.cartItems.find((item) => item._id === newItem._id);
//         //     const foundItem = state.cartItems.find((item) => item._id === comingItem.cartItemId);

//         //     if (foundItem) {
//         //          {foundItem, comingItem}
//         //     }
//         // },

//         updateCartSuccess: (state, action) => {
//             state.isFetching = false;
//             const comingItem = action.payload;
//             const foundItemIndex = state.cartItems.findIndex(item => item._id === comingItem.cartItemId);
        
//             if (foundItemIndex !== -1) {
//                 // Spread the properties of foundItem into a new object
//                 const updatedItem = {
//                     ...state.cartItems[foundItemIndex],
//                     ...comingItem
//                 };
//                 // Replace the old item with the updated one
//                 state.cartItems[foundItemIndex] = updatedItem;
//             }
//         },
        

//         increaseQuantity: (state, action) => {
//             const productId = action.payload;
//             const item = state.cartItems.find((item) => item._id === productId);
//             if (item) {
//                 item.quantity++;
//                 state.quantity++;
//                 state.total += item.price;
//                 state.totalInDollar += item.priceInDollar;
//             }
//         },
//         decreaseQuantity: (state, action) => {
//             const productId = action.payload;
//             const item = state.cartItems.find((item) => item._id === productId);
//             if (item && item.quantity > 1) {
//                 item.quantity--;
//                 state.quantity--;
//                 state.total -= item.price;
//                 state.totalInDollar -= item.priceInDollar;
//             }
//         },

//         addToCartFailure: (state) => {
//             // state.isFetching = false;
//             // state.error = true;
//         },

//         removeFromCartStart: (state) => {

//         },

//         // ===================************* old method **************======================
//         // removeFromCartSuccess: (state, action) => {
//         //     const itemId = action.payload;
//         //     const itemToRemove = state.cartItems.find((item) => item._id === itemId);


//         //     if (itemToRemove) {
//         //         state.cartItems = state.cartItems.filter((item) => item._id !== itemId);
//         //         state.total -= itemToRemove.price * itemToRemove.quantity;
//         //         state.totalInDollar -= itemToRemove.priceInDollar * itemToRemove.quantity;
//         //         state.quantity -= itemToRemove.quantity;
//         //     }
//         // },

//         removeFromCartSuccess: (state, action) => {
//             const itemId = action.payload;
//             const itemToRemove = state.cartItems.find((item) => item._id === itemId);


//             if (itemToRemove) {
//                 state.cartItems = state.cartItems.filter((item) => item._id !== itemId);
//                 state.total -= itemToRemove.price * itemToRemove.quantity;
//                 state.totalInDollar -= itemToRemove.priceInDollar * itemToRemove.quantity;
//                 state.quantity -= itemToRemove.quantity;
//             }
//         },

//         removeFromCartFailure: (state) => {

//         },

//         addCoupon: (state, action) => {
//             const { couponCode, discountType, amount } = action.payload;
//             state.appliedCoupon = couponCode;
//             state.discountType = discountType;
//             state.discount = amount;
//         },
//         // Add a new reducer to set the original amount
//         setOriginalAmount: (state, action) => {
//             state.total = action.payload;
//             state.totalInDollar = action.payload;
//         },
//         // Add a new reducer to set the discounted amount
//         setDiscountedAmount: (state, action) => {
//             state.discountedAmount = action.payload;
//         },

//         removeCoupon: (state) => {
//             state.appliedCoupon = '';
//             state.discountType = '';
//             state.discount = 0;
//             state.discountedAmount = 0
//         },

//         clearCart: (state) => {
//             state.cartItems = [];
//             state.total = 0;
//             state.totalInDollar = 0;
//             state.quantity = 0;
//         }

//     },
// });

// export const { getCartProductStart, getCartProductSuccess, getCartProductFailure, addToCartStart, addToCartSuccess, addToCartFailure, removeFromCartStart, removeFromCartSuccess, removeFromCartFailure, clearCart, increaseQuantity, decreaseQuantity, addCoupon, setOriginalAmount, setDiscountedAmount, removeCoupon, updateCartSuccess } = addToCartReducer.actions;
// export default addToCartReducer.reducer;





// ================new code below===========


import { createSlice } from "@reduxjs/toolkit";

const addToCartReducer = createSlice({
    name: 'cart',
    initialState: {
        cartItems: [],
        total: 0,
        // totalInDollar : 0,
        quantity: 0,
        discountedAmount: 0, // The cart amount after applying discounts
        quantity: 0,
        appliedCoupon: '',
        discountType: '',
        discount: 0,
    },

    reducers: {
        // // add to cart actions
        addToCartStart: (state) => {
            // state.isFetching = true;
        },
        addToCartSuccess: (state, action) => {
            const newItem = action.payload;
            // console.log("coming data in reducer ==>", newItem)
            const existingItem = state.cartItems.find((item) => item._id === newItem._id);

            if (existingItem) {
                // If the item already exists in the cart, update its quantity
                existingItem.quantity += newItem.quantity;
                state.total += newItem.price * newItem.quantity;
                // state.totalInDollar += newItem.priceInDollar * newItem.quantity;
                state.quantity += newItem.quantity;
            } else {
                // If the item is not already in the cart, add it as a new item
                state.cartItems.push(newItem);
                state.total += newItem.price * newItem.quantity;
                // state.totalInDollar += newItem.priceInDollar * newItem.quantity;
                state.quantity += newItem.quantity;
            }
            // const newItem = action.payload;
            // const existingItem = state.cartItems.find((item) => item._id === newItem._id);

            // if (existingItem) {
            //     // If the item already exists in the cart, update its quantity
            //     existingItem.quantity += newItem.quantity;
            //     state.total += newItem.price * newItem.quantity;
            //     state.totalInDollar += newItem.priceInDollar * newItem.quantity;
            //     state.quantity += newItem.quantity;
            // } else {
            //     // If the item is not already in the cart, add it as a new item
            //     state.cartItems.push(newItem);
            //     state.total += newItem.price * newItem.quantity;
            //     state.totalInDollar += newItem.priceInDollar * newItem.quantity;
            //     state.quantity += newItem.quantity;
            // }
        },

        increaseQuantity: (state, action) => {
            const productId = action.payload;
            const item = state.cartItems.find((item) => item._id === productId);
            if (item) {
                item.quantity++;
                state.quantity++;
                state.total += item.price;
                // state.totalInDollar += item.priceInDollar;
            }
        },
        decreaseQuantity: (state, action) => {
            const productId = action.payload;
            const item = state.cartItems.find((item) => item._id === productId);
            if (item && item.quantity > 1) {
                item.quantity--;
                state.quantity--;
                state.total -= item.price;
                // state.totalInDollar -= item.priceInDollar;
            }
        },

        addToCartFailure: (state) => {
            // state.isFetching = false;
            // state.error = true;
        },

        removeFromCartStart: (state) => {

        },

        removeFromCartSuccess: (state, action) => {
            const itemId = action.payload;
            const itemToRemove = state.cartItems.find((item) => item._id === itemId);

            if (itemToRemove) {
                state.cartItems = state.cartItems.filter((item) => item._id !== itemId);
                state.total -= itemToRemove.price * itemToRemove.quantity;
                // state.totalInDollar -= itemToRemove.priceInDollar * itemToRemove.quantity;
                state.quantity -= itemToRemove.quantity;
            }
        },

        removeFromCartFailure: (state) => {

        },

        addCoupon: (state, action) => {
            const { couponCode, discountType, amount } = action.payload;
            state.appliedCoupon = couponCode;
            state.discountType = discountType;
            state.discount = amount;
        },
        // Add a new reducer to set the original amount
        setOriginalAmount: (state, action) => {
            state.total = action.payload;
            // state.totalInDollar = action.payload;
        },
        // Add a new reducer to set the discounted amount
        setDiscountedAmount: (state, action) => {
            state.discountedAmount = action.payload;
        },

        removeCoupon: (state) => {
            state.appliedCoupon = '';
            state.discountType = '';
            state.discount = 0;
            state.discountedAmount = 0
        },

        clearCart: (state) => {
            state.cartItems = [];
            state.total = 0;
            // state.totalInDollar = 0;
            state.quantity = 0;
        }

    },
});

export const { addToCartStart, addToCartSuccess, addToCartFailure, removeFromCartStart, removeFromCartSuccess, removeFromCartFailure, clearCart, increaseQuantity, decreaseQuantity, addCoupon, setOriginalAmount, setDiscountedAmount, removeCoupon } = addToCartReducer.actions;
export default addToCartReducer.reducer;