import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa";
import "./ContactUsPage.css";
import { useState } from "react";
import { publicRequest } from "../requestMethod";
import { validateRequired } from "../formValidation";
const ContactUsPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  const submitFormQuery = async (user) => {
    // dispatch(addUserStart());
    try {
      setIsLoaderActive(true);
      const res = await publicRequest.post("contactForm/create", user);
      // dispatch(addUserSuccess(res.data));

      if (res && res.status === 200) {
        setIsLoaderActive(false);
        setSuccess(true);
        setSuccessMessage("We have recieved your query.");
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
      }
    } catch (error) {
      setIsLoaderActive(false);
      setError(true);
      setErrorMessage("Something went wrong!");
    }
  };

  // Validate function for form validation start =======
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    const nameValidErr = validateRequired(name);
    const emailValidErr = validateRequired(email);
    const phoneValidErr = validateRequired(phone, "phone");

    if (nameValidErr) {
      formIsValid = false;
      newErrors.name = nameValidErr;
    }
    if (emailValidErr) {
      formIsValid = false;
      newErrors.email = emailValidErr;
    }
    if (phoneValidErr) {
      formIsValid = false;
      newErrors.phone = phoneValidErr;
    }

    setErrors(newErrors);
    return formIsValid;
  };
  // Validate function for form validation end======

  const submitData = async () => {
    setSuccess(false);
    setError(false);
    if (validateForm()) {
      submitFormQuery({
        name,
        email,
        phone,
        message,
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && validateForm()) {
      submitData();
    }
  };

  return (
    <div className="">
      {/* 1st Section */}
      <section className="container contactUs_page pb-50">
        <div className="row contactP1 align-items-center">
          <div className="col-md-6">
            <img
              src="images/contactUs.png"
              alt="Contact"
              className="img-fluid"
            />
          </div>
          <div className="col-md-6 ps-lg-5">
            <h2 className="text-uppercase my-5">
              Drop us a <span>Message</span>
            </h2>
            {/* <form> */}
            <div className="mb-3">
              <label htmlFor="fullName" className="form-label">
                Full Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="What’s your name"
                id="fullName"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <span className="error-text">{errors.name}</span>
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                placeholder="you@example.com"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="error-text">{errors.email}</span>
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
              <input
                type="tel"
                placeholder="+XX XXXX-XXXX-XX"
                className="form-control"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <span className="error-text">{errors.phone}</span>
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                id="message"
                rows="5"
                placeholder="Write your message for the team here"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyPress}
              ></textarea>
            </div>
            <div className="row">
              <div className="col-12 mt-3">
                {success ? (
                  <div className="alert alert-success">{successMessage}</div>
                ) : (
                  error && (
                    <div className="alert alert-danger">{errorMessage}</div>
                  )
                )}
              </div>
            </div>
            {/* <Link
              to=""
              className="uniBtn uniBtnBlue my-0 mx-auto d-block btnPLight w-fitC"
            >
              Send
            </Link> */}
            <div className="coupon-btn-wrapper text-center ">
              <button
                type="submit"
                className={`${isLoaderActive ? "button-loader load" : "submit-coupon"
                  }`}
                onClick={submitData}
              >
                <span>Submit</span>
              </button>
            </div>
            {/* </form> */}
          </div>
        </div>
      </section>

      {/* 2nd Section */}
      <section className="pb-100 pt-100 connect">
        <div className="row d-flex justify-content-center m-5">
          <div className="col-md-4">
            <div className="text-center">
              <div className="card_envalop">
                <div>
                  <FaEnvelope size={30} />
                </div>
                <h5 className="card-title">Email</h5>
                <p className="card-text">info@meritus.ai</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="text-center">
              <div className="card_phone">
                <div>
                  <FaPhone size={30} />
                </div>
                <h5 className="card-title">Phone</h5>
                <p className="card-text">+91 93821 55525</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 3rd Section */}

      <section className="container pt-50 map_container pb-100">
        <h2 className="text-uppercase">
          Where to <span>find us</span>
        </h2>
        <div className="row">
          <div className="col-md-6">
            <div className="Map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15549.759080385138!2d80.2581142!3d13.0076434!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265e624edc7c3%3A0xc7e45ee8372ff6c1!2sRobotix%20Learning%20Solutions!5e0!3m2!1sen!2sin!4v1694260311788!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-between align-items-center">
            <div className="map_card mt-4 mt-md-0">
              <FaMapMarkerAlt size={70} />
              <div className="map-body">
                <h5 className="map-title">India Address</h5>
                <p className="map-text">
                  Meritus AI Learning Experiences Private Limited
                  <br />
                  "Krishna Sree", 44, 2nd Floor, 
                  <br /> 1st Main Road, Gandhi Nagar,
                  <br />
                  Adyar, Chennai - 600 020, Tamil Nadu, India
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <div className="map_card">
              <FaMapMarkerAlt size={70} />
              <div className="map-body">
                <h5 className="map-title">USA Address</h5>
                <p className="map-text">
                  Meritus AI Learning Experiences Private Limited
                  <br /> 651 N Broad St, Suite 201, Middletown, Delaware 19709
                  U.S.A.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3080.5954191965625!2d-75.71745229999999!3d39.4558755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c7a079b74b8557%3A0x6ce80b96a55fea8b!2s651%20N%20Broad%20St%20%23%20201%2C%20Middletown%2C%20DE%2019709%2C%20USA!5e0!3m2!1sen!2sin!4v1694261046055!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUsPage;
