import React, { useEffect } from 'react';
import './PDFViewer.css'
// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'react-pdf';
import { useState } from 'react';
// import { Document, Page } from 'react-pdf';
// import pdf from './Meritus-Dashboard.pdf'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { AiFillCloseCircle } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import { MEDIA_URL } from '../../config';
import "pdf-viewer-root";
import 'pdfjs-dist/web/pdf_viewer.css';

const pdfVersion = "3.12.0"

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.12.0/pdf.worker.js`
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

// async function PDFViewer(props) {
//     const docs = [
//         {
//             uri: MEDIA_URL + props.docData.url,
//             fileType: "pdf",
//             fileName: props.docData.fileName
//         }, // Remote file
//     ];

//     await props.updateResourceProgressFunction(props.docData.chapterIndex, props.docData.resourceIndex, 100)

//     console.log("coming pdf props ==>",props )

//     return (
//         <div className="pdf-container" onContextMenu={(e) => e.preventDefault()}>
//             <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
//         </div>
//     )
// }


const PDFViewer = (props) => {
    const docs = [
        {
            uri: MEDIA_URL + props.docData.url,
            fileType: "pdf",
            fileName: props.docData.fileName
        }, // Remote file
    ];

    // let currentPage = 1;
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null);

    // useEffect(() => {
    //     const loadDocument = async () => {
    //         try {
    //             const loadingTask = pdfjs.getDocument(url);
    //             const pdf = await loadingTask.promise;
    //             setTotalPages(pdf.numPages);
    //         } catch (error) {
    //             console.error('Error loading PDF document:', error);
    //         }
    //     };

    //     loadDocument();
    // }, [props.docData.url]);

    const handlePage = () => {
        // currentPage += 1
        setCurrentPage(currentPage + 1);
        console.log("current page ==>", currentPage)
    }

    const handlePdfAfterLoad = (pdf) => {
        console.log("pdf after load ==>", )
    }


    useEffect(() => {
        const updateProgress = async () => {
            await props.updateResourceProgressFunction(props.docData.chapterIndex, props.docData.resourceIndex, 100)
        }
        updateProgress();
    },[props])



    // // console.log("coming pdf props ==>", props)

    // return (
    //     <div className="pdf-container" onContextMenu={(e) => e.preventDefault()}>
    //         <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
    //     </div>
    // )
    return (
        <div className="pdf-container" onContextMenu={(e) => e.preventDefault()}>
            {/* <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} /> */}
            {/* {React.createElement("pdf-viewer-root", { url: MEDIA_URL + props.docData.url, page : 2 })} */}
            <pdf-viewer-root url={MEDIA_URL + props.docData.url} page={currentPage} mode={2} />
            {/* <button>prev</button>
            <button onClick={handlePage}>next</button> */}

        </div>
    )
}




export default PDFViewer;