import { useSelector } from "react-redux";
import { getUserDetails } from "../config";
import { publicRequest, userRequest } from "../requestMethod";
import {
  getBlogCategoryFailure,
  getBlogCategoryStart,
  getBlogCategorySuccess,
} from "./blogCategoryRedux";
import { getBlogFailure, getBlogStart, getBlogSuccess } from "./blogRedux";
import { addToCartSuccess, getCartProductFailure, getCartProductStart, getCartProductSuccess } from "./cartRedux";
import { getSectionFailure, getSectionStart, getSectionSuccess } from "./customSectionRedux";
import { getMilestoneFailure, getMilestoneStart, getMilestoneSuccess } from "./milestoneRedux";
import { getOrdersFailure, getOrdersStart, getOrdersSuccess } from "./orderRedux";
import { getProductFailure, getProductStart, getProductSuccess } from "./productRedux";
import { getQuizFailure, getQuizStart, getQuizSuccess } from "./quizRedux";
import { getRatingsFailure, getRatingsStart, getRatingsSuccess } from "./ratingRedux";
import { getRobotixProductFailure, getRobotixProductStart, getRobotixProductSuccess } from "./robotixProductRedux";
import { getStoriesVideoFailure, getStoriesVideoStart, getStoriesVideoSuccess } from "./successStoriesRedux";
import { getWishlistProductFailure, getWishlistProductStart, getWishlistProductSuccess } from "./wishListRedux";

// Get All Products
export const getProducts = async (dispatch) => {
  dispatch(getProductStart());
  try {
    const res = await publicRequest.get('/product/all');
    // Filter products where status is true
    // const location = "USA"
    // let filteredProducts;
    // let productsWithPriceFlag;
    //  const filteredProducts = res.data.filter(product => product.status === true);
    // if (location === 'India') {
    //   filteredProducts = res.data.filter(product => product.status === true && (product.availability === 'onlyForIndia' || product.availability === 'all'));

    //   productsWithPriceFlag = filteredProducts?.map(product => {
    //     return {
    //       ...product,
    //       showPriceInRupees: true
    //     };
    //   });
    // } else {
    //   filteredProducts = res.data.filter(product => product.status === true && (product.availability === 'onlyForOutOfIndia' || product.availability === 'all'));
    //   productsWithPriceFlag = filteredProducts?.map(product => {
    //     return {
    //       ...product,
    //       showPriceInRupees: false
    //     };
    //   });
    // }

    // dispatch(getProductSuccess(productsWithPriceFlag));
    const filteredProducts = res?.data?.filter(product => product?.status === true);
    // productsWithPriceFlag = filteredProducts?.map(product => {
    //   return {
    //     ...product,
    //     showPriceInRupees: false
    //   };
    // });
    dispatch(getProductSuccess(filteredProducts));
  } catch (error) {
    dispatch(getProductFailure());
  }
};

// Get Specific Product
// export const getSpecificProduct = async (dispatch, id) => {
//     dispatch(getProductStart());
//     try {
//         const res = await publicRequest.get(`/product/find/${id}`);
//         dispatch(getProductSuccess(res.data));
//     } catch (error) {
//         dispatch(getProductFailure());
//     }
// };

// Get all orders api call
export const getAllOrders = async (dispatch, id) => {
  dispatch(getOrdersStart());
  try {
    const res = await userRequest.get(`/order/find/${id}`);
    dispatch(getOrdersSuccess(res.data));
  } catch (error) {
    dispatch(getOrdersFailure());
  }
};

// Get lectured details api call
export const getLecture = async (dispatch, id) => {
  dispatch(getOrdersStart());
  try {
    const res = await userRequest.get(`/order/find/${id}`);
    dispatch(getOrdersSuccess(res.data));
  } catch (error) {
    dispatch(getOrdersFailure());
  }
};

// Get all blogs api call
export const getAllBlogs = async (dispatch) => {
  dispatch(getBlogStart());
  try {
    const res = await userRequest.get("/blog/all");
    const activeBlogsOnly = res.data?.filter((blog) => blog?.status === true);
    dispatch(getBlogSuccess(activeBlogsOnly));
  } catch (error) {
    dispatch(getBlogFailure());
  }
};

// Get all blogs categories api call
export const getAllBlogCategory = async (dispatch) => {
  dispatch(getBlogCategoryStart());
  try {
    const res = await userRequest.get("/blogCategory/all");
    dispatch(getBlogCategorySuccess(res.data));
  } catch (error) {
    dispatch(getBlogCategoryFailure());
  }
};

// Get all course ratings api call
export const getSpecificCategoryBlog = async (dispatch, category) => {
  dispatch(getBlogStart());
  try {
    const res = await publicRequest.get(`/blog/find/${category}`);
    const activeBlogsOnly = res.data?.filter((blog) => blog?.status === true);
    dispatch(getBlogSuccess(activeBlogsOnly));
  } catch (error) {
    dispatch(getBlogFailure());
  }
};

// Get all course ratings api call
export const getAllRatings = async (dispatch) => {
  dispatch(getRatingsStart());
  try {
    const res = await publicRequest.get("/rating/all");
    dispatch(getRatingsSuccess(res.data));
  } catch (error) {
    dispatch(getRatingsFailure());
  }
};

// Get all course ratings api call
export const getSpecificCourseRating = async (dispatch, id) => {
  dispatch(getRatingsStart());
  try {
    const res = await publicRequest.get(`/rating/find/${id}`);
    dispatch(getRatingsSuccess(res.data));
  } catch (error) {
    dispatch(getRatingsFailure());
  }
};

// Get all course ratings api call
export const getQuiz = async (dispatch, id) => {
  dispatch(getQuizStart());
  try {
    const res = await userRequest.get(`/quiz/find/${id}`);
    dispatch(getQuizSuccess(res.data));
  } catch (error) {
    dispatch(getQuizFailure());
  }
};

// Get All Robotix Products
export const getRobotixProducts = async (dispatch) => {
  dispatch(getRobotixProductStart());
  try {
    const res = await publicRequest.get("/robotix/product/all");
    const filteredProducts = res.data.filter(
      (product) => product.status === true
    );
    dispatch(getRobotixProductSuccess(filteredProducts));
  } catch (error) {
    dispatch(getRobotixProductFailure());
  }
};

// Get all custom sections api call
export const getAllCustomSections = async (dispatch) => {
  dispatch(getSectionStart());
  try {
    const res = await publicRequest.get("/customSection/all");
    const filteredSections = res.data.filter(
      (section) => section.status === true
    );
    dispatch(getSectionSuccess(filteredSections));
  } catch (error) {
    dispatch(getSectionFailure());
  }
};

// Get all success stories videos api call
export const getAllSuccessStoriesVideo = async (dispatch) => {
  dispatch(getStoriesVideoStart());
  try {
    const res = await publicRequest.get("/success-stories/all");
    const filteredStories = res.data?.filter((item) => item.status === true);
    dispatch(getStoriesVideoSuccess(filteredStories));
  } catch (error) {
    dispatch(getStoriesVideoFailure());
  }
};

// Get all success stories videos api call
export const getAllMilestones = async (dispatch) => {
  dispatch(getMilestoneStart());
  try {
    const res = await publicRequest.get("/milestone/all");
    const filteredMilestones = res.data?.filter((item) => item.status === true);
    dispatch(getMilestoneSuccess(filteredMilestones));
  } catch (error) {
    dispatch(getMilestoneFailure());
  }
};

// Get all wishlist products api call
// export const getAllWishlistProducts = async (dispatch, id) => {
//   dispatch(getWishlistProductStart());
//   try {
//     const res = await userRequest.get(`/wishlist/find/${id}`);
//     // const filteredMilestones = res.data?.filter(item => item.status === true);
//     dispatch(getWishlistProductSuccess(res.data));
//   } catch (error) {
//     dispatch(getWishlistProductFailure());
//   }
// };

// const allProducts = useSelector((state) => state);

// Get all cart products api call
// export const getAllCartProducts = async (dispatch, id) => {
//   dispatch(getCartProductStart());
//   try {
//     const res = await userRequest.get(`/cart/find/${id}`);
//     // if (res.data?.length > 0) {
//     //   res.data.map((item) => {
//     //     if (item.productId) {
//     //       dispatch(addToCartSuccess(item));
//     //     }
//     //   });
//     // } else{
//       dispatch(getCartProductSuccess(res.data))
//     // }
//   } catch (error) {
//     dispatch(getCartProductFailure());
//   }
// };


// const cartItems = cartProducts?.map(cartProduct => {
//     // Find the product in the products array based on productId
//     const product = products?.find(product => product._id === cartProduct.productId);
//     if (product) {
//       // Add wishlistId property to the product
//       return {
//         ...product,
//         cartProductId: cartProduct._id,
//         quantity: cartProduct.quantity
//       };
//     }
//     return null; // Handle case where product is not found
//   }).filter(Boolean); // Filter out any null values if product is not found