import TrendingNow from "../component/common/trending/TrendingNow";
import BlogCard from "../component/BlogCard";
import "./BlogAndNewsFrame.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { MEDIA_URL, changeDateFormat, isUrl } from "../config";
import RenderHTMLContent from "../RenderHTMLContent";
import { useEffect } from "react";
import { getAllBlogCategory, getAllBlogs } from "../redux/apiCalls";
const BlogAndNewsFrame = ({ itemCategory, itemType, image, title, author, description, }) => {

  const dispatch = useDispatch();

  // useEffect(() => {
  //   getAllBlogs(dispatch);
  // }, []);

  useEffect(() => {
    getAllBlogCategory(dispatch);
  }, []);

  const blogs = useSelector((state) => state.blog.blogs);

  const blogCategories = useSelector(
    (state) => state.blogCategory.blogCategory
  );
  const currentCategoryBlogs =
    blogs && blogs.filter((item) => item.category === itemCategory);
  const currentCategoryId =
    blogCategories &&
    blogCategories.find((item) => item.category === itemCategory);
  const items = [
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-1",
    },
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-2",
    },
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-3",
    },
    {
      text: "UNESCO unveils new AI roadmap for classrooms",
      link: "/trending-item-4",
    },
  ];
  const blogcardData = [
    {
      id: 1,
      imageUrl: "images/latestNews/img-1.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and tyspesetting industry.",
      read: "/",
    },
    {
      id: 2,
      imageUrl: "images/latestNews/img-2.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      read: "/",
    },
    {
      id: 3,
      imageUrl: "images/latestNews/img-3.jpg",
      title: "Better future for children lavale village, pune",
      date: "Feb 2020",
      paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      read: "/",
    },
  ];
  return (
    <div className="news_container pb-30">
      {/* {
        currentBlog && (
          <> */}
      <div className="news_banner position-relative">
        <div className="container ">
          <p className="fw-300 pSize">
            <span className="text-capitalize"> Latest {itemType}</span>
          </p>
          <h2 className="inh2" style={{textTransform : "none"}}>{title}</h2>
          <p className="fw-600 pSize">By {author}</p>
        </div>
      </div>
      <div className="container">
        <div className="blog-image-wrapper">
          {isUrl(image) ? (
            <img src={image} alt="news-img" className="blog-image" />
          ) : (
            <img src={MEDIA_URL+ image} />
          )}
        </div>
        {<RenderHTMLContent htmlContent={description} />}
      </div>
      {
        itemType === 'blog' && (
          <section className="pb-100">
            <h2 className="container inh2 pb-md-4">
              Browse<span> Blogs</span>
            </h2>
            <div className="container">
              <div className="row">
                {currentCategoryBlogs &&
                  currentCategoryBlogs.map((blogCard, index) => {
                    if (index < 3) {
                      return (
                        <div className="col-md-4" key={blogCard._id}>
                          <BlogCard blog={blogCard} />
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
            <div className="btn-wrapper text-center">
              <Link
                to={`/category-blog/${itemCategory && itemCategory}`}
              >
                <button className="uniBtn btnPLight uniBtnBlue mx-auto">
                  View All
                </button>
              </Link>
            </div>
          </section>
        )
      }
    </div>
  );
};

export default BlogAndNewsFrame;
