import 'bootstrap/dist/css/bootstrap.css';
import '../styles.css';
import '../order-details-pg/OrderSummary.css';
import CommonButton from '../common/Button/CommonButton';
import { Container, Row, Col, Table } from 'react-bootstrap';

const OrderSummary = (props) => {
    console.log("coming props ==>", props)
    return (
        <div className='order-summary pt-40 mt-50'>
            <Container>
                <Row className='m-0'>
                    <Col lg={12} className='p-0'>
                        <div className='heading up-tab-heading'>
                            <h1>Order <span>Summary</span></h1>
                        </div>
                    </Col>
                    <Col lg={12} className='p-0'>
                        <div className='delivery-add sub-head-odp'>
                            <h4>Delivery Address</h4>
                        </div>
                        <Col lg={2}>
                            <div className='user-add'>
                                {/* <p>
                                    Manya Sharma
                                    Sethia Grandeur, 203
                                    400051
                                    Mumbai, Maharashtra,
                                    India
                                </p> */}
                                <p className='text-capitalize'><span>{props?.summary?.address.firstName}</span> <span>{props?.summary?.address.lastName}</span></p>
                                <p>{props?.summary?.address.address}</p>
                                <p>{props?.summary?.address.city}</p>
                                <p>{props?.summary?.address.pincode}</p>
                                <p>{props?.summary?.address.phoneNumber}</p>
                                {/* <p>{props.address.address}</p> */}
                            </div>
                        </Col>
                    </Col>
                    <Col lg={12} className='p-0'>
                        <div className='payment-mode sub-head-odp'>
                            <h4>Payment Status</h4>
                        </div>
                        <Col lg={2}>
                            <div className='user-add'>
                                {
                                    props?.summary?.paymentStatus === "success" ? (
                                        <p>
                                            Paid
                                        </p>
                                    ) : (
                                        <p className='text-capitalize'>{props?.summary?.paymentStatus}</p>
                                    )
                                }

                            </div>
                        </Col>
                    </Col>
                    {/* <Col lg={12} className='text-center'>
                        <CommonButton
                            btnClass="cancel-order-btn"
                            btnName="Cancel order"
                        />
                    </Col> */}

                    {/* MRP Breakup Table */}
                    <Table className='product-price-breakup ff mt-50'>
                        <tr>
                            <td>MRP.</td>
                            {
                                props.summary?.currency === 'INR' && (
                                    <td className='text-end'>&#8377;{props?.summary?.amount}</td>
                                )
                            }
                            {
                                props.summary?.currency === 'USD' && (
                                    <td className='text-end'>&#x24; {props?.summary?.amount}</td>

                                )
                            }

                            
                        </tr>
                        {
                            props?.summary?.isCouponApplied ? (
                                <>
                                    <tr>
                                        <td>Discount</td>
                                        {
                                            props?.summary?.discountType == "percentage" && (
                                                <td className='text-end'>{props?.summary?.discount}&#37;</td>
                                                // console.log("coming in per")
                                            )
                                        }
                                        {
                                            props?.summary?.discountType === "fixed" && (
                                                <>
                                                    {
                                                        props.summary?.currency === 'INR' && (
                                                            <td className='text-end'>&#8377; {props?.summary?.discount}</td>
                                                        )
                                                    }
                                                    {
                                                        props.summary?.currency === 'USD' && (
                                                            <td className='text-end'>&#x24; {props?.summary?.discount}</td>

                                                        )
                                                    }
                                                </>
                                            )
                                        }

                                    </tr>

                                    <tr>
                                        <td>Total after discount</td>
                                        {
                                            props.summary?.currency === 'INR' && (
                                                <td className='text-end'>&#8377; {props?.summary?.discountedAmount}</td>
                                            )
                                        }
                                        {
                                            props.summary?.currency === 'USD' && (
                                                <td className='text-end'>&#x24; {props?.summary?.discountedAmount}</td>
                                            )
                                        }

                                    </tr>

                                    {/* <tr>
                                        <td>Discount</td>
                                        <td className='text-end'>&#8377;0</td>
                                    </tr> */}
                                </>
                            ) : (
                                <tr className='total-bill-amt'>
                                    <td className='total-txt'>Total</td>
                                    {
                                        props.summary?.currency === 'INR' && (
                                            <td className='total-amt text-end'>&#8377; {props?.summary?.amount}</td>
                                        )
                                    }
                                    {
                                        props.summary?.currency === 'USD' && (
                                            <td className='total-amt text-end'>&#x24; {props?.summary?.amount}</td>
                                        )
                                    }
                                </tr>
                            )
                        }
                    </Table>
                </Row>
            </Container>
        </div>
    )
}

export default OrderSummary;