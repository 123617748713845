// import "bootstrap/dist/css/bootstrap.css";
// import "../../styles.css";
// import "../cards/StemProductCard.css";
// import { Card } from "react-bootstrap";
// import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
// import CommonButton from "../Button/CommonButton";
// import { useDispatch, useSelector } from "react-redux";
// import { useState } from "react";
// import {
//   addProductToWishListFailure,
//   addProductToWishListStart,
//   addProductToWishListSuccess,
//   removeProductFromWishListFailure,
//   removeProductFromWishListStart,
//   removeProductFromWishListSuccess,
// } from "../../../redux/wishListRedux";
// import { Link } from "react-router-dom";
// import {
//   addToCartFailure,
//   addToCartStart,
//   addToCartSuccess,
// } from "../../../redux/cartRedux";
// import { MEDIA_URL, truncateText } from "../../../config";
// import { userRequest } from "../../../requestMethod";

// const StemProductCard = (props) => {
//   // Cheking user is logged or not=====
//   const user = useSelector((state) => state.loginUser.currentUser);

//   const state = useSelector((state) => state);
//   const [isHeartExploded, setHeartExploded] = useState(false);
//   // const [featuredProduct, setFeaturedProduct] = useState([]);

//   const products = useSelector((state) => state.product.products);

//   // Calculate the sum of ratings
//   const totalRating = props.ratingForCurrentProduct?.reduce(
//     (acc, curr) => acc + curr.rating,
//     0
//   );
//   // Calculate the average rating
//   const averageRating = totalRating
//     ? totalRating / props.ratingForCurrentProduct?.length
//     : null;

//   const dispatch = useDispatch();

//   const addProductToWishList = async (dispatch, product) => {
//     dispatch(addProductToWishListStart());
//     try {
//       const res = await userRequest.post('/wishlist/create', { productId: product._id, userId: user._id });
//       if (res?.status === 200) {
//         dispatch(addProductToWishListSuccess(res.data));
//       }
//       setHeartExploded(true);
//     } catch (error) {
//       dispatch(addProductToWishListFailure());
//     }
//   };

//   const handleAddProductToWishList = (product) => {
//     addProductToWishList(dispatch, product);
//   };

//   const removeProductFromWishList = async (dispatch, wishlistId) => {
//     dispatch(removeProductFromWishListStart());
//     try {
//       // const res = await userRequest.post('user/create', product);
//       const res = await userRequest.delete(`/wishlist/${user?._id}`, { data: { wishlistId } });
//       if (res?.status === 200) {
//         dispatch(removeProductFromWishListSuccess(wishlistId));
//       }

//       // setHeartExploded(false);
//     } catch (error) {
//       dispatch(removeProductFromWishListFailure());
//     }
//   };

//   const wishlist = useSelector((state) => state.wishList.wishListItems);


//   const handleRemoveProductFromWishList = (productId) => {
//     const currentWishlistItem = wishlist?.find((item) => item.productId === productId)
//     removeProductFromWishList(dispatch, currentWishlistItem?._id);
//   };

//   // Set the maximum length for the description
//   const maxDescriptionLength = 100;

//   // Function to check if a product is in the wishlist
//   const isProductInWishlist = (productid) => {
//     return wishlist.some((product) => product.productId === productid);
//   };

//   const addProductToCart = async (dispatch, product) => {
//     dispatch(addToCartStart());
//     try {
//       const res = await userRequest.post('/cart/create', { productId: product._id, userId: user._id });
//       if (res?.status === 200) {
//         // dispatch(addProductToWishListSuccess(res.data));
//         dispatch(addToCartSuccess(res.data));
//       }
//     } catch (error) {
//       dispatch(addToCartFailure());
//     }
//   };

//   const handleAddToCart = () => {
//     addProductToCart(dispatch, { ...props, quantity: 1 });
//   };

//   return (
//     <div className="stem-product-card">
//       {/* Stem Product Card  */}
//       <Card className="spc-custom">
//         {/* Image Section of the card */}
//         <Link to={`/product/${props.title}`}>
//           <Card.Img variant="top" src={MEDIA_URL + props.productImg} />
//         </Link>
//         {/* Title section of the card */}
//         <Card.Body className="spc-content-wrapper">
//           <Card.Title className="spc-title-wrapper" title={props?.title}>
//             <div className="spc-title">
//               <h3>{truncateText(props?.title, 65)}</h3>
//             </div>

//             <div className="whishlist-icon">
//               {/* <AiOutlineHeart /> */}
//               {user ? (
//                 isProductInWishlist(props._id) ? ( // Check if the product is in the wishlist
//                   <AiFillHeart
//                     className="heart-icon"
//                     onClick={() => handleRemoveProductFromWishList(props._id)}
//                   />
//                 ) : (
//                   <AiOutlineHeart
//                     onClick={() => handleAddProductToWishList(props)}
//                   />
//                 )
//               ) : (
//                 <Link to={"/login"}>
//                   <AiOutlineHeart />
//                 </Link>
//               )}
//             </div>
//           </Card.Title>
//           {/* Description section of Card  */}
//           <Card.Text className="spc-text-rating-wrapper">
//             <div className="product-desc m-0">
//               {props?.shortDescription && (
//                 <p className="m-0">
//                   {truncateText(props?.shortDescription, 120)}
//                 </p>
//               )}
//             </div>
//             <div className="rating-section">
//               {/* <span className="rating">★★★★★</span> 4.7 */}
//               {[...Array(5)].map((_, index) => {
//                 // Calculate the difference between the index and the average rating
//                 const diff = index + 0.5 - averageRating;
//                 const className =
//                   diff < 0
//                     ? "rated-star"
//                     : diff === 0
//                       ? "rated-star"
//                       : diff < 0.5
//                         ? "half-star"
//                         : "grey-star";

//                 return (
//                   <span key={index} className={className}>
//                     &#9733;
//                   </span>
//                 );
//               })}
//               <span>{averageRating?.toFixed(1)}</span>
//             </div>
//           </Card.Text>
//           {/* Price and Buy Button Section of the Card */}
//           <div className="buy-price-wrapper">
//             <div className="buy-btn-wrapper">
//               {/* <Link to={`/robotix-product/${props._id}`}>
//                 <CommonButton btnClass="spc-buy-btn" btnName="Buy Now" />
//               </Link> */}
//               {props?.priceVisibility ? (
//                 user ? (
//                   <Link to={"/cart"}>
//                     <CommonButton
//                       onClickAction={handleAddToCart}
//                       btnClass="spc-buy-btn"
//                       btnName={
//                         props.freeInIndia && props.price === 0
//                           ? "Learn Now"
//                           : "Buy Now"
//                       }
//                     />
//                   </Link>
//                 ) : (
//                   <Link to={"/login"}>
//                     <CommonButton btnClass="spc-buy-btn" btnName="Buy Now" />
//                   </Link>
//                 )
//               ) : (
//                 <Link to={"/contact-us"}>
//                   <CommonButton
//                     btnClass={`spc-buy-btn ${props.customClass || ""}`}
//                     btnName="Contact Us"
//                   />
//                 </Link>
//               )}
//             </div>
//             {props?.priceVisibility && (
//               <div className="price-wrapper">
//                 {props.freeInIndia && props.price === 0 && (
//                   <p>
//                     <strong>Free</strong>
//                   </p>
//                 )}

//                 {props.showPriceInRupees
//                   ? !props.freeInIndia &&
//                     !props.isForSubscription && (
//                       <p>
//                         <strong>&#8377;{props?.price}</strong>
//                       </p>
//                     )
//                   : !props.freeInIndia &&
//                     !props.isForSubscription && (
//                       <p>
//                         <strong>&#x24;{props?.price}</strong>
//                       </p>
//                     )}
//                 {props.showPriceInRupees
//                   ? props.isForSubscription &&
//                     props.price !== 0 && (
//                       <>
//                         <p>Pay Only</p>
//                         <p>
//                           <strong>&#8377;{`${props.price}/Month`}</strong>
//                         </p>
//                       </>
//                     )
//                   : props.isForSubscription &&
//                     props.price !== 0 && (
//                       <>
//                         <p>Pay Only</p>
//                         <p>
//                           <strong>
//                             &#x24;{`${props.price}/Month`}
//                           </strong>
//                         </p>
//                       </>
//                     )}
//                 {/* {props.isForSubscription && props.price !== 0 && (
//                 <>
//                   <p>Pay Only</p>
//                   <p>
//                     <strong>&#8377;{`${props.price}/Month`}</strong>
//                   </p>
//                 </>
//               )} */}
//                 {/* <p>
//                 <strong>&#8377;{props.price}</strong>
//               </p> */}
//               </div>
//             )}
//           </div>
//         </Card.Body>
//       </Card>
//     </div>
//   );
// };

// export default StemProductCard;




// ===========new code bolow=======

import "bootstrap/dist/css/bootstrap.css";
import "../../styles.css";
import "../cards/StemProductCard.css";
import { Card } from "react-bootstrap";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import CommonButton from "../Button/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  addProductToWishListFailure,
  addProductToWishListStart,
  addProductToWishListSuccess,
  removeProductFromWishListFailure,
  removeProductFromWishListStart,
  removeProductFromWishListSuccess,
} from "../../../redux/wishListRedux";
import { Link } from "react-router-dom";
import {
  addToCartFailure,
  addToCartStart,
  addToCartSuccess,
} from "../../../redux/cartRedux";
import { MEDIA_URL, truncateText } from "../../../config";

const StemProductCard = (props) => {
  // Cheking user is logged or not=====
  const user = useSelector((state) => state.loginUser.currentUser);

  const product = useSelector((state) => state);
  const [isHeartExploded, setHeartExploded] = useState(false);
  // const [featuredProduct, setFeaturedProduct] = useState([]);

  // Calculate the sum of ratings
  const totalRating = props.ratingForCurrentProduct?.reduce(
    (acc, curr) => acc + curr.rating,
    0
  );
  // Calculate the average rating
  const averageRating = totalRating
    ? totalRating / props.ratingForCurrentProduct?.length
    : null;

  const dispatch = useDispatch();

  const addProductToWishList = async (dispatch, product) => {
    dispatch(addProductToWishListStart());
    try {
      dispatch(addProductToWishListSuccess(product));
      setHeartExploded(true);
    } catch (error) {
      dispatch(addProductToWishListFailure());
    }
  };

  const handleAddProductToWishList = (product) => {
    addProductToWishList(dispatch, product);
  };

  const removeProductFromWishList = (dispatch, productId) => {
    dispatch(removeProductFromWishListStart());
    try {
      // const res = await userRequest.post('user/create', product);
      dispatch(removeProductFromWishListSuccess(productId));
      setHeartExploded(false);
    } catch (error) {
      dispatch(removeProductFromWishListFailure());
    }
  };

  const wishlist = useSelector((state) => state.wishList.wishListItems);

  const handleRemoveProductFromWishList = (productId) => {
    removeProductFromWishList(dispatch, productId);
  };

  // Set the maximum length for the description
  const maxDescriptionLength = 80;

  // Function to check if a product is in the wishlist
  const isProductInWishlist = (productId) => {
    return wishlist.some((product) => product._id === productId);
  };

  const addProductToCart = async (dispatch, props) => {
    dispatch(addToCartStart());
    try {
      dispatch(addToCartSuccess(props));
    } catch (error) {
      dispatch(addToCartFailure());
    }
  };

  const handleAddToCart = () => {
    addProductToCart(dispatch, { ...props, quantity: 1 });
  };

  return (
    <div className="stem-product-card">
      {/* Stem Product Card  */}
      <Card className="spc-custom">
        {/* Image Section of the card */}
        <Link to={`/product/${props.title}`}>
          <Card.Img variant="top" src={MEDIA_URL + props.productImg[0]} />
        </Link>
        {/* Title section of the card */}
        <Card.Body className="spc-content-wrapper">
          <Card.Title className="spc-title-wrapper" title={props?.title}>
            <div className="spc-title">
              <h3>{truncateText(props?.title, 60)}</h3>
            </div>

            <div className="whishlist-icon">
              {/* <AiOutlineHeart /> */}
              {user ? (
                isProductInWishlist(props._id) ? ( // Check if the product is in the wishlist
                  <AiFillHeart
                    className="heart-icon"
                    onClick={() => handleRemoveProductFromWishList(props._id)}
                  />
                ) : (
                  <AiOutlineHeart
                    onClick={() => handleAddProductToWishList(props)}
                  />
                )
              ) : (
                <Link to={"/login"}>
                  <AiOutlineHeart />
                </Link>
              )}
            </div>
          </Card.Title>
          {/* Description section of Card  */}
          <Card.Text className="spc-text-rating-wrapper">
            <div className="product-desc m-0">
              {props?.shortDescription && (
                <p className="m-0">
                  {truncateText(props?.shortDescription, 110)}
                </p>
              )}
            </div>
            <div className="rating-section">
              {/* <span className="rating">★★★★★</span> 4.7 */}
              {[...Array(5)].map((_, index) => {
                // Calculate the difference between the index and the average rating
                const diff = index + 0.5 - averageRating;
                const className =
                  diff < 0
                    ? "rated-star"
                    : diff === 0
                    ? "rated-star"
                    : diff < 0.5
                    ? "half-star"
                    : "grey-star";

                return (
                  <span key={index} className={className}>
                    &#9733;
                  </span>
                );
              })}
              <span>{averageRating?.toFixed(1)}</span>
            </div>
          </Card.Text>
          {/* Price and Buy Button Section of the Card */}
          <div className="buy-price-wrapper">
            <div className="buy-btn-wrapper">
              {/* <Link to={`/robotix-product/${props._id}`}>
                <CommonButton btnClass="spc-buy-btn" btnName="Buy Now" />
              </Link> */}
              {props?.priceVisibility ? (
                user ? (
                  <Link to={"/cart"}>
                    <CommonButton
                      onClickAction={handleAddToCart}
                      btnClass="spc-buy-btn"
                      btnName={
                        props.freeInIndia && props.price === 0
                          ? "Learn Now"
                          : "Buy Now"
                      }
                    />
                  </Link>
                ) : (
                  <Link to={"/login"}>
                    <CommonButton btnClass="spc-buy-btn" btnName="Buy Now" />
                  </Link>
                )
              ) : (
                <Link to={"/contact-us"}>
                  <CommonButton
                    btnClass={`spc-buy-btn ${props.customClass || ""}`}
                    btnName="Contact Us"
                  />
                </Link>
              )}
            </div>
            {props?.priceVisibility && (
              <div className="price-wrapper">
                {props.freeInIndia && props.price === 0 && (
                  <p>
                    <strong>Free</strong>
                  </p>
                )}

                {props.showPriceInRupees
                  ? !props.freeInIndia &&
                    !props.isForSubscription && (
                      <p>
                        <strong>&#8377;{props?.price}</strong>
                      </p>
                    )
                  : !props.freeInIndia &&
                    !props.isForSubscription && (
                      <p>
                        <strong>&#x24;{props?.price}</strong>
                      </p>
                    )}
                {props.showPriceInRupees
                  ? props.isForSubscription &&
                    props.price !== 0 && (
                      <>
                        <p>Pay Only</p>
                        <p>
                          <strong>&#8377;{`${props.price}/Month`}</strong>
                        </p>
                      </>
                    )
                  : props.isForSubscription &&
                    props.price !== 0 && (
                      <>
                        <p>Pay Only</p>
                        <p>
                          <strong>
                            &#x24;{`${props.price}/Month`}
                          </strong>
                        </p>
                      </>
                    )}
                {/* {props.isForSubscription && props.price !== 0 && (
                <>
                  <p>Pay Only</p>
                  <p>
                    <strong>&#8377;{`${props.price}/Month`}</strong>
                  </p>
                </>
              )} */}
                {/* <p>
                <strong>&#8377;{props.price}</strong>
              </p> */}
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default StemProductCard;